.blogs-page .latest-article {
  font-size: 28px;
}
.blogs-page .mn-post-title h2 {
  font-size: 28px;
  line-height: 36px;
}
.breadcrumb {
  padding-top: 20px;
  padding-bottom: 14px;
  line-height: 28px;
}
.breadcrumb .unactive a {
  font-family: "Light";
  color: var(--gray-light);
  text-decoration: none;
}
.breadcrumb .greater a {
  color: var(--gray-light);
  text-decoration: none;
}
.breadcrumb .activelink a {
  font-family: "Regular";
  color: var(--black);
  text-decoration: none;
}
.breadcrumb .activelink {
  font-family: "Regular";
}
[dir="rtl"] .breadcrumb .activelink {
  font-family: "Gulzar";
}
[dir="rtl"] .breadcrumb {
  font-family: "Gulzar";
}
.col-6-title {
  font-size: 24px !important;
  line-height: 36px !important;
}
.col-4-title {
  font-size: 20px !important;
  line-height: 28px !important;
}
.blogs-page .blogs-main {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 991px) {
  .breadcrumb {
    padding-top: 30px;
  }
}
@media screen and (max-width: 575px) {
  .blogs-page .blogs-main {
    margin-bottom: 0;
  }
  .blogs-page .mn-post-title h2{
    font-size: 16px;
    line-height: 24px;
  }
  .breadcrumb {
    padding-top: 32px;
    font-size: 14px;
    display: block;
    margin-bottom: 0;
  }
}

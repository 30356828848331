.header-back-drop-logo {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: -1;
}
[dir="rtl"] .header-back-drop-logo {
  transform: rotateY(180deg);
  left: 0;
  right: inherit;
}
.welcome {
  color: var(--teal);
  font-size: 18px;
  text-transform: uppercase;
  font-family: "SemiBold";
}
[dir="rtl"] .welcome{
  margin-bottom: 25px;
}
.explore-product-section {
  position: relative;
  padding: 80px 0 0;
}
.get-customize a {
  color: var(--black);
}
.hero-section {
  margin-bottom: 140px;
}
.explore-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 50px;
}
.get-quote-img {
  position: absolute;
  right: -90px;
  top: -80px;
}
.explore-box .primary-btn {
  padding: 14px 36px;
}
.brand-section .section-heading {
  color: var(--white);
}
.trusted p {
  padding: 0 100px;
}
.brand-section {
  margin-top: 0;
  border-radius: 0;
}
.home-title .main-heading {
  font-size: 28px;
  line-height: 36px;
}
[dir="rtl"] .home-title .main-heading {
  line-height: 50px;
}
.zaraye-hero-section {
  padding-top: 50px;
}
.collaborative {
  padding-top: 140px;
  padding-bottom: 40px;
}
.brand-item {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
}
.brand-item .brand-item-img {
  height: 50%;
}
.brand-item-img img {
  width: 70px;
}
.brand-item span {
  color: var(--black);
  text-decoration: none;
  text-align: center;
  height: 36%;
  padding: 0 20px;
  font-family: 'Regular';
}
.navigate-intricacies-section {
  /* padding-bottom: 70px; */
}
.company-brands .brand-item img {
  width: 100px;
}
.company-brands .brand-slider div {
  justify-content: center;
}
.company-manufactures {
  margin-bottom: 60px;
}
.valued-investor-section .company-manufactures .brand-item {
  height: 140px;
}
.investors-img .brand-item img {
  width: 140px;
}
.header-back-drop-logo img {
  width: 100%;
}
.company-pg .zaraye-core-values .section-heading,
.company-pg .zaraye-core-values .section-description {
  color: var(--white);
}
.about-brand-slider-section .slick-slider {
  margin: 0 -15px;
}
.about-brand-slider-section .slick-slide {
  padding: 0 15px;
}
.valued-investor-section {
  position: relative;
}
.core-values-slider .brand-item {
  width: 240px;
}
@media screen and (max-width: 1700px) {
  .header-back-drop-logo {
    width: 35vw;
  }
}
@media screen and (max-width: 1340px) {
  .header-back-drop-logo {
    width: 37vw;
  }
}
@media screen and (max-width: 1199px) {
  .company-pg .get-customize {
    margin-left: 10px;
    font-size: 14px;
    margin-top: 10px;
  }
  .vid-container video {
    height: 500px;
  }
  .video-column {
    margin-top: -250px;
  }
}
@media screen and (max-width: 1024px) {
  .header-back-drop-logo {
    width: 40vw;
  }
  .company-pg .home-title .main-heading {
    font-size: 22px;
    line-height: 30px;
  }
  .company-pg .explore-box {
    margin-top: 20px;
  }
}

@media screen and (max-width: 991px) {
  .vid-container video {
    height: 350px;
  }
  .collaborative {
    padding-top: 60px;
  }
  .header-back-drop-logo {
    width: 35vw;
    top: 80px;
  }
  .brand-item span {
    height: 42%;
  }
  .brand-item-img img {
    width: 60px;
  }
  .brand-item {
    padding: 20px 12px;
  }
  .core-values-slider .brand-item {
    height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .header-back-drop-logo {
    display: none;
  }
   .company-pg .company-brands .brand-slider .brand-item {
    height: 160px;
  }
  .company-pg .get-customize {
    width: 100%;
  }
  .company-pg .zaraye-core-values .about-brand-slider-section {
    /* margin-top: -80px; */
  }
  .valued-investor-section .all-brands-resp {
    position: absolute;
    margin-top: 0;
    bottom: -40px;
  }
  .company-pg .brand-slider .brand-item {
    height: 210px;
  }
  .company-img {
    height: 277px;
    object-fit: cover;
    border-radius: 25px;
    margin-bottom: 50px;
  }
  .company-pg .hero-section {
    margin-bottom: 50px;
  }
  .company-pg .zaraye-core-values {
    background-color: var(--teal);
    /* position: relative; */
  }
  .company-manufactures {
    /* margin-bottom: 200px; */
  }
  .valued-investor-section {
    margin: 0 0 40px;
    padding-bottom: 100px;
  }
  .valued-investor-section .brand-slider {
    bottom: 90px;
  }
  .valued-investor-section .zaraye-core-values {
    /* margin: 220px 0; */
  }
  .company-pg .zaraye-core-values .section-heading {
    font-size: 22px;
  }
  .collaborative {
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .company-pg .one-stop-solution-section{
    padding-top: 20px;
  }
  .about-brand-slider-section .slick-slider {
    margin: 0 -10px;
  }
  .about-brand-slider-section .slick-slide {
    padding: 0 10px;
  }
  .investors-img .brand-item img {
    width: 120px;
  }
}
@media screen and (max-width: 576px) {
  .valued-investor-section .company-manufactures .brand-item {
    height: 105px;
  }
  .company-pg .zaraye-hero-section {
    padding-top: 0;
}
  .valued-investor-section .brand-slider {
    left: 0;
    right: 0;
  }
  .valued-investor-section {
    padding-bottom: 70px;
  }
  .cmx-auto{
    margin: 0;
  }
  .investors-img .brand-item img {
    width: 100px;
  }
  .company-pg .brand-slider .brand-item {
    height: 180px;
  }
  .brand-slider .brand-item img {
    width: 50px;
  }
  .brand-item .brand-item-img {
    height: 44%;
  }
  .brand-item span {
    height: 48%;
  }
  .valued-investor-section .brand-slider {
    bottom: 90px;
  }
  .company-pg .company-brands .brand-slider .brand-item{
    height: 120px;
  }
  .navigate-intricacies-section {
    border-radius: 25px 25px 0px 0px;
  }
}

@media screen and (max-width: 500px) {
  .cmx-auto .core-values-slider .brand-slider .slick-initialized .slick-slide {
    margin: 0 6px;
  }
  .company-pg .vid-container video {
    height: 260px;
  }
  .company-pg .video-column {
    margin-top: -200px;
  }
  .company-pg .explore-product-section .navigate-intricacies-section {
    margin-top: 240px;
  }
}
@media screen and (max-width: 420px) {
  .valued-investor-section .all-brands-resp {
    bottom: -50px;
  }
  .brand-item span {
    font-size: 14px;
    padding: 0 10px;
  }
  .our-visionaries-section .section-heading {
    font-size: 22px;
    line-height: 30px;
  }
  .our-visionaries-section {
    margin-top: 70px !important;
  }
  .our-customer-section .section-heading {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
  }
  .investors-img .brand-item img {
    width: 80px;
  }
  .valued-investor-section .brand-slider {
    bottom: 70px !important;
  }
  .company-pg .vid-container video {
    height: 210px;
  }
  .company-pg .video-column {
    margin-top: -160px;
  }
  .company-pg .explore-product-section .navigate-intricacies-section {
    margin-top: 200px;
  }
}
@media screen and (max-width: 360px) {
  .brand-item span {
    font-size: 12px;
  }
  .company-pg .brand-slider .brand-item {
    height: 160px;
  }
}
@media screen and (max-width: 340px) {
  .company-pg .vid-container video {
    height: 190px;
  }
  .company-pg .explore-product-section .navigate-intricacies-section {
    margin-top: 220px;
  }
}
@media screen and (max-width: 320px) {
  .brand-item span {
    height: 42%;
  }
  .brand-item .brand-item-img {
    height: 46%;
  }
}

.sitemap-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.sitemap-list li{
    float: left;
    padding: 11px;
}
.sitemap-list li a{
    text-align: center;
}
.topic-details p {
    margin-bottom: 0;
}

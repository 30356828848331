.search-counts {
    font-family: 'SemiBold';
    font-size: 18px;
    margin-bottom: 40px;
}
.search-product-list .spl-img{
    width: 17.53%;
    height: 150px;
    border-radius: 10px;
    overflow: hidden;
}
.spl-cat-name{
    font-size: 14px;
    color: var(--Black);
}
.spa-published{
    font-size: 14px;
}
.search-product-list .spl-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.search-product-list .spl-content{
    width: 79.5%;
}
.spl-content-category{
    color: var(--black);
}
.spl-content-category h4{
    font-family: 'SemiBold';
    font-size: 24px;
    line-height: 24px;
}
.search-product-list {
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.spa-list h6{
    font-size: 18px;
    font-family: 'SemiBold';
    line-height: 26px;
    color: #000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;
}
.search-pg h2{
    font-size: 28px;
    font-family: 'SemiBold';
    font-weight: 600;
    line-height: 36px;
}
.spa-title{
    margin-bottom: 38px;
}
.spa-list {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
}
.search-pg .grid-product .products {
    width: 24%;
    margin-top: 0;
}
.search-pg .grid-product {
    gap:11px;
}
.spa-list h6,
.search-product-list .spl-content-detail,
.search-product-articles .spa-list p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
}
.search-product-articles .spa-list p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;
}
@media screen and (max-width: 1199px) {
    .search-product-list .spl-img {
        width: 23%;
        height: 140px;
    }
    .search-product-list .spl-content {
        width: 74%;
    }
    .spl-content-category h4 {
        font-size: 20px;
    }
    .spa-published {
        font-size: 13px;
    }
}
@media screen and (max-width: 767px) {
    .search-product-list .spl-content {
        width: 70%;
    }
    .search-product-list .spl-img {
        width: 26%;
        height: 130px;
    }
    .search-product-list {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .spa-title {
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 475px) {
    .search-product-list .spl-content {
        width: 71%;
    }
    .search-product-list .spl-img {
        width: 25%;
        height: 120px;
    }
    .spl-content-category h4 {
        font-size: 18px;
        line-height: 20px;
    }
    .search-pg h2 {
        font-size: 22px;
    }
}
@media screen and (max-width: 425px) {
    .search-product-list .spl-img {
        height: 100px;
    }
}
@media screen and (max-width: 360px) {
    .search-product-list .spl-content {
        width: 68%;
    }
    .search-product-list .spl-img {
        width: 28%;
    }
    .spl-content-category h4 {
        font-size: 16px;
    }
}
.top-header {
    border-right: 1px solid var(--black);
    padding: 0px 1.2rem;
    font-size: 14px;
    margin: 10px 0;
}
.top-header span{
    font-family: 'Regular';
}
.currency {
    color: var(--gray-light);
}
@media screen and (max-width: 1400px) {
    .top-header {
        padding: 0px 1rem;
        font-size: 12px;
    }
}
@media screen and (max-width: 767px) {
    .top-header {
        padding: 0px 0.5rem;
        font-size: 11px;
    }
    .top-header img {
        width: 9px;
    }
}
@media screen and (max-width: 575px) {
    .top-header {
        padding: 0px 0.5rem;
        font-size: 11px;
    }
    .currency-slider-container {
        display: none;
    }
}
@media screen and (max-width: 380px) {
    .top-header {
        padding: 0px 0.5rem;
        font-size: 10px;
    }
}
@media screen and (max-width: 330px) {
    .top-header {
        padding: 0px 0.3rem;
        font-size: 9px;
    }
}
/* .red {
    color: var(--danger) !important;
}
.green {
    color: var(--success) !important;
} */

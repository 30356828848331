.modal-dialog {
  /* width: 545px;
  max-width: 100%; */
}
.modal-content {
  /* border-radius: 25px; */
}
.myModal {
  padding: 50px;
}
.modalTitle {
  color: var(--black);
  text-align: center;
  font-family: SemiBold;
  font-size: 24px;
}
.modal-footer {
  justify-content: space-between;
  border: 0;
}
.modal-footer .secondary-btn,
.modal-footer .primary-btn {
  padding: 14px 29px;
  font-size: 14px;
}

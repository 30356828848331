.custom-tab-buttons {
  border-bottom: 1px solid #ccc;
  display: flex;
  padding-left: 30px;
}
.tab-button {
  font-family: "Regular";
  margin-bottom: -1px;
  cursor: pointer;
}
.tab-button.active .my-tab {
  border: 1px solid #ccc;
  border-bottom-color: transparent;
  background-color: var(--white);
}
.my-tab {
  border: 1px solid transparent;
  border-radius: 12px 12px 0 0;
  padding: 8px 15px;
}
.tab-button.active .my-tab::after {
  content: "";
  width: 10px;
  height: 10px;
  background: aqua;
}
.tab-content {
    font-family: 'Regular';
}

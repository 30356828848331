.solution-section {
  width: 18%;
  margin: 16px 0px;
}
.solution-section h5 {
  margin-top: 20px;
  font-size: 20px;
  font-family: "SemiBold";
  text-align: center;
}
.explore-product-section .navigate-intricacies-section {
  margin-top: 400px;
}
.navigate-intricacies-row p {
  font-family: "Light";
}
.one-stop-solution-section .navigate-intricacies-section {
  padding-bottom: 60px;
}
@media screen and (max-width: 991px) {
  .solution-section img {
    width: 90px;
  }
  .solution-section h5 {
    font-size: 16px;
  }
  .explore-product-section .navigate-intricacies-section {
    margin-top: 300px;
  }
  .navigate-intricacies-row {
    margin-top: 70px;
  }
}
@media screen and (max-width: 767px) {
  .solution-section {
    width: 49%;
    margin: 16px 0px;
  }
  .home-title{
    margin-bottom: 25px;
  }
  .home-title:nth-child(3){
    margin-bottom: 0;
  }
}

.career-pg .brand-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0 320px;
  margin-bottom: 260px;
  border-radius: 70px 70px 0px 0px;
}
.life-of-zaraye {
  position: absolute;
  bottom: -260px;
  border-radius: 30px;
  border: 3px solid var(--teal);
  background: var(--white);
  backdrop-filter: blur(50px);
  padding-left: 80px;
  height: 546px;
}
.life-of-zaraye p {
  font-size: 16px;
  color: var(--gray-light);
  padding-top: 86px;
  padding-right: 30px;
}

.life-of-zaraye .content-section .content-naming {
  padding: 24px 0px;
}
.life-of-zaraye .content-section .content-naming h5 {
  color: var(--black);
  font-size: 24px;
  font-family: "SemiBold";
  line-height: 36px;
}
.life-of-zaraye .content-section .content-naming span {
  font-size: 16px;
  line-height: 28px;
  color: var(--gray-light);
  font-family: "Italic";
}
.careers-gallery .life-1 {
    border-bottom-left-radius: 40px;
}
.careers-gallery .life-2 {
    border-top-right-radius: 40px;
}
.life-of-zaraye .image-section {
  position: absolute;
  top: 46px;
  right: -30px;
}
.life-of-zaraye .slick-slider {
  height: 100%;
}
.life-of-zaraye .slick-slider .slick-list {
  height: 100%;
}
.life-of-zaraye .slick-slider .slick-list .slick-track .slick-slide {
  position: relative;
}
.slick-slider-fade .slick-track {
  -webkit-transition: opacity 5s ease-in-out;
  -moz-transition: opacity 5s ease-in-out;
  -ms-transition: opacity 5s ease-in-out;
  -o-transition: opacity 5s ease-in-out;
  opacity: 1;
}
.careers-page .section-heading {
  font-size: 28px;
  line-height: 36px;
  font-family: "SemiBold";
}
.careers-page .redefining-sourcing-section {
  margin-top: 0px;
}
.careers-page .redefining-sourcing-section {
  margin-bottom: 5rem;
}
.life-of-zaraye .slick-prev {
  bottom: 40px;
  top: inherit;
  width: 80px;
  left: 0;
  z-index: 1;
}
.life-of-zaraye .slick-next {
  bottom: 40px;
  top: inherit;
  left: 100px;
  width: 80px;
  z-index: 1;
}
.groups-img {
  display: none;
}
.careers-page .careers-gallery {
  padding: 120px 0;
}
.careers-peoples img {
  width: auto;
}
@media screen and (max-width: 1399px) {
  .life-of-zaraye .image-section {
    right: 10px;
  }
}
@media screen and (max-width: 1199px) {
  .careers-peoples img {
    width: 100%;
  }
  .life-of-zaraye {
    padding-left: 60px;
  }
  .life-of-zaraye p {
    padding-top: 66px;
  }
  .life-of-zaraye .image-section {
    right: 60px;
    top: 70px;
  }
}
@media screen and (max-width: 991px) {
  .careers-peoples img {
    width: auto;
    margin: auto;
  }
  .life-of-zaraye {
    padding-left: 40px;
    padding-right: 40px;
    height: auto;
    border: none;
    background: transparent;
    -webkit-backdrop-filter: none;
    backdrop-filter: blur(0px);
    position: inherit;
    bottom: 0;
    margin-top: 60px;
    text-align: center;
  }
  .careers-page .brand-section {
    background-color: transparent;
    padding: 0;
    margin-bottom: 60px;
  }
  .careers-page .brand-section .section-heading {
    color: var(--black);
  }
  .careers-page .brand-section p {
    color: var(--gray-light);
  }
  .life-of-zaraye p {
    padding-top: 66px;
    padding-right: 0px;
  }
  .life-of-zaraye .image-section {
    right: 60px;
    top: 70px;
    position: inherit;
  }
  .content-section {
    order: 2;
  }
  .image-section {
    order: 1;
  }
  .image-section img {
    position: inherit;
    margin: auto;
  }
  .career-pg .brand-section {
    flex-direction: column;
  }
  .redefining-sourcing-section .buildings-careers {
    order: 2;
  }
  .redefining-sourcing-section .careers-peoples {
    order: 1;
  }
  .careers-page .buildings-careers {
    margin-top: 60px;
    text-align: center;
  }
  .life-of-zaraye .slick-next {
    /* left: 59%; */
    margin-top: 20px;
    bottom: -50px;
    /* translate: -50% -50%; */
    z-index: 10;
    left: 0;
    right: -100px;
    margin: 0 auto;
    width: 60px;
  }
  .life-of-zaraye .slick-prev {
    /* left: 38%; */
    margin-top: 20px;
    bottom: -50px;
    /* translate: -50% -50%; */
    z-index: 10;
    left: -100px;
    right: 0;
    margin: 0 auto;
    width: 60px;
  }
  .groups-img-xl {
    display: none;
  }
  .careers-page .careers-gallery {
    padding: 80px 0;
  }
  .groups-img {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .careers-peoples img {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .careers-peoples img {
    width: 100%;
    padding: 0 40px;
  }
  .life-of-zaraye p {
    padding-right: 0px;
  }
  .life-of-zaraye .items {
    margin: auto;
  }
  /* .life-of-zaraye .slick-next {
    left: 62%;
  } */
  .careers-page .section-heading {
    font-size: 22px;
    line-height: 30px;
  }
  .life-of-zaraye {
    margin-top: 20px;
    padding: 0 20px;
  }
  .life-of-zaraye p {
    padding-top: 20px;
  }
  .careers-page .careers-gallery {
    padding: 60px 0;
  }
}
@media screen and (max-width: 450px) {
  /* .life-of-zaraye .slick-prev {
    left: 34%;
  }
  .life-of-zaraye .slick-next {
    left: 66%;
  } */
  .image-section img {
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .life-of-zaraye .slick-prev {
    left: -90px;
  }
  .life-of-zaraye .slick-next {
    right: -90px;
  }
}
@media screen and (max-width: 370px) {
  .life-of-zaraye {
    margin-top: 20px;
    padding: 0 10px;
  }
}

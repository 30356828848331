
.topic-detail-pg h1,
.topic-detail-pg h2,
.topic-detail-pg h3,
.topic-detail-pg h4,
.topic-detail-pg h5,
.topic-detail-pg h6,
.topic-detail-pg * strong {
  font-family: "SemiBold";
  color: var(--black);
}
.topic-detail-pg  * a {
  text-decoration: none;
  color: var(--teal);
}
.topic-detail-pg ul li a p {
  margin-bottom: 0;
}
.topic-detail-pg .d-block.mb-5:nth-child(3) img {
  border-radius: 10px;
}

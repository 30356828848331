.loading-body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: #fffc;
}
.circle-path {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 10px;
  width: 10px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.circle-path div {
  background: var(--teal);
  border-radius: 100%;
  height: 10px;
  position: absolute;
  width: 10px;
  -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translate(0, 30px);
  transform: rotate(calc(var(--rotation) * 1deg)) translate(0, 30px);
}
.circle-path div:nth-child(11) {
  background: var(--gray-light);
  -webkit-animation: path 1s infinite steps(10);
  animation: path 1s infinite steps(10);
}
.circle-path div:nth-child(1) {
  --rotation: 36;
}
.circle-path div:nth-child(2) {
  --rotation: 72;
}
.circle-path div:nth-child(3) {
  --rotation: 108;
}
.circle-path div:nth-child(4) {
  --rotation: 144;
}
.circle-path div:nth-child(5) {
  --rotation: 180;
}
.circle-path div:nth-child(6) {
  --rotation: 216;
}
.circle-path div:nth-child(7) {
  --rotation: 252;
}
.circle-path div:nth-child(8) {
  --rotation: 288;
}
.circle-path div:nth-child(9) {
  --rotation: 324;
}
.circle-path div:nth-child(10) {
  --rotation: 360;
}
@-webkit-keyframes path {
  from {
    -webkit-transform: rotate(0deg) translate(0, 30px) scale(1.5);
    transform: rotate(0deg) translate(0, 30px) scale(1.5);
  }
  to {
    -webkit-transform: rotate(360deg) translate(0, 30px) scale(1.5);
    transform: rotate(360deg) translate(0, 30px) scale(1.5);
  }
}
@keyframes path {
  from {
    -webkit-transform: rotate(0deg) translate(0, 30px) scale(1.5);
    transform: rotate(0deg) translate(0, 30px) scale(1.5);
  }
  to {
    -webkit-transform: rotate(360deg) translate(0, 30px) scale(1.5);
    transform: rotate(360deg) translate(0, 30px) scale(1.5);
  }
}

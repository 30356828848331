.grid-product .products {
  width: 23.2%;
  margin-top: 0;
  padding: 0;
  border: 0;
}
.grid-product .prod-box-height img {
  border-radius: 10px;
}
.prod-box-height {
  position: relative;
  overflow: hidden;
}
.prod-box-height img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}
.prod-box-height .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) -5.81%, #000 100%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* padding-bottom: 20px; */
  overflow: hidden;
  transition: height 0.3s, opacity 0.3s;
  border-radius: 10px;
}
.prod-box-height:hover .overlay {
  height: 100%;
  opacity: 1;
}
.prod-box-height .buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  opacity: 0;
  transform: translateY(-20px);
  padding-bottom: 20px;
  /* transition: opacity 0.3s, transform 0.3s; */
}
.prod-box-height:hover .buttons {
  opacity: 1;
  transform: translateY(0);
}
.prod-box-height .primary-btn,
.prod-box-height .secondary-btn {
  padding: 8px 20px;
  border: 0;
}
.grid-product {
  /* display: grid !important; */
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  gap: 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 50px;
}
.list-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.grid-layout .toggle-buttons {
  display: flex;
}
.grid-layout .toggle-buttons button {
  margin-right: 10px;
}
.grid-layout .toggle-buttons button.active {
  background-color: #e0e0e0;
}
.grid-filter-row {
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .grid-filter-row {
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 425px) {
 
  .filter-page-size p {
    font-size: 12px;
  }
  .product-list-pg .grid-product {
    gap: 0;
    justify-content: space-between;
  }
}

.become-supplier-pg {
  position: relative;
}
.become-supplier-pg .main-form {
  padding: 40px;
  border: 2px solid var(--teal);
  border-radius: 40px;
}
.become-supplier-pg .explore-box {
  display: none;
}
ul.bcm-sup-list {
  display: flex;
  justify-content: space-between;
  width: 80%;
  flex-wrap: wrap;
  color: var(--black);
  font-family: "Regular";
  margin-top: 40px;
}
ul.bcm-sup-list li {
  width: 50%;
  margin-bottom: 10px;
}
.become-supplier-pg .css-jvce2r-control,
.become-supplier-pg .css-eu97x1-control {
  height: 47px;
}
.become-supplier-pg .main-form .css-mohuvp-dummyInput-DummyInput {
  padding: 7px 20px;
}
.become-supplier-pg .main-form input,
.become-supplier-pg .main-form textarea {
  padding: 14.3px 20px;
}
.become-supplier-pg .css-1uc3fkr-container {
  padding: 0.5px 10px;
}
@media screen and (max-width: 767px) {
  .main-heading {
    font-size: 22px;
    line-height: 30px;
  }
  ul.bcm-sup-list li {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 420px) {
  .become-supplier-pg .main-form {
    padding: 30px;
  }
}

.solution-pg-title h2 {
  padding-right: 100px;
}
.solution-pg-title p {
  padding-right: 50px;
}
[dir="rtl"] .solution-pg-title p,
[dir="rtl"] .solution-pg-title h2,
[dir="rtl"] .title-logo{
  padding-right: 0;
  text-align: right;
}
[dir="rtl"] .solution-pg-title h2{
  line-height: 48px;
}
.title-logo img {
  width: 18%;
  margin-bottom: 30px;
}
.dw-zortal-section {
  margin-top: 150px;
}
.sp-app-logo:nth-child(1){
  margin-right: 20px;
}
[dir="rtl"] .sp-app-logo:nth-child(1){
  margin-right: 0px;
  margin-left: 20px;
}
[dir ="rtl"] .exp-zortal .primary-btn {
  padding: 20px 50px 12px;
}
@media screen and (max-width: 1199px) {
  .solution-pg-title h2 {
    padding-right: 20px;
  }
  .solution-pg-title p {
    padding-right: 0px;
  }
}
@media screen and (max-width: 991px) {
  .solution-resp .solution-image img {
    margin: auto;
    display: flex;
  }
  .solution-pg-title {
    padding-top: 20px;
  }
  .dw-zortal-section {
    margin-top: 100px;
  }
}
@media screen and (max-width: 767px) {
  .solution-pg-title h2 {
    padding-right: 0px;
  }
  .title-logo{
    text-align: center;
  }
}
@media screen and (max-width: 575px) {
  .landing-header-title h1 {
    font-size: 22px;
    line-height: 30px;
  }
  .industry-landing-page .section-heading {
    font-size: 22px;
    text-transform: capitalize;
    line-height: 30px;
  }
  .solution-pg-title h2 {
    padding-right: 0;
  }
  .title-logo img {
    width: 35%;
    margin-top: 30px;
  }
  .solution-pg-title p {
    padding-right: 0;
  }
  .sp-app-logo:nth-child(1){
    margin-right: 0;
}
  .sp-app-logo {
    width: 45%;
  }
  .sp-app-logo img {
    width: 100%;
  }
  .dw-zortal-section {
    margin-top: 70px;
  }
  .solution-app {
    width: 400px;
    margin: auto;
  }
  .exp-zortal {
    display: flex;
    justify-content: center;
  }
  .exp-zortal button {
    margin: auto;
  }
}
@media screen and (max-width: 420px) {
  .solution-app {
    width: 320px;
    margin: auto;
  }
}
@media screen and (max-width: 350px) {
  .solution-app {
    width: 280px;
    margin: auto;
  }
}

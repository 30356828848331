.price-insights-filters input {
  border-radius: 50px;
  border: 1px solid #0f8a8c;
  padding: 15px 20px;
  font-size: 14px;
}
.price-insights-filters .css-1iljrc3-control {
  min-height: 50px;
}
.price-insights-filters .css-cfq1sy-container {
  margin-top: 0;
}

.real-time-price-page .all-products {
  display: none;
}
.real-time-price-page .table-cont tbody {
  max-height: 3000px;
  padding-right: 0px;
}
[dir="rtl"] .real-time-price-page .table-cont tbody {
  padding-left: 0;
}
.no-data-found {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 22px;
  padding: 20px 0;
  width: 100%;
}
.real-time-price-page .css-1fgiqq8-control svg {
  height: 35px;
}
.css-1agocms-control svg {
  height: 35px !important;
}
@media screen and (max-width: 991px) {
  .real-time-price-page .brand-filter-btn {
    margin: auto;
  }
}

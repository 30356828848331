.msg-row{
    margin-top: 100px;
}
[dir ="rtl"] .msg-row .co-founders {
    text-align: right !important;
}

@media screen and (max-width: 767px) {
    .msg-row{
        margin-top: 0;
    }
    .msg-row .section-heading {
        margin-top: 50px;
    }
}
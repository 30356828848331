.products {
  box-sizing: border-box;
  margin-top: 30px;
  text-align: center;
  border: 0;
}
.column-gap {
  column-gap: 1.5rem;
}
.selling-products-slider .slick-list {
  margin: 0 -15px !important;
}
.selling-products-slider .slick-initialized .slick-slide {
  padding: 0px 15px 20px 15px;
}

.products h5 {
  color: var(--black);
  font-family: "Semibold";
  font-size: 16px;
  margin-top: 20px;
}

.products h6 {
  font-family: "Regular";
  line-height: normal;
  text-decoration: underline;
  cursor: pointer;
  color: var(--black);
  font-weight: 500;
}
[dir="rtl"] .products h6 {
  text-decoration: none;
  line-height: inherit;
}
.view-all {
  font-family: "Regular";
  font-size: 16px;
  line-height: normal;
  text-decoration: underline;
  color: var(--black);
  cursor: pointer;
}
.heading-bb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 5px;
  margin: 0px;
}
.cat-thumbnail {
  display: block;
  overflow: hidden;
  background-size: 100% !important;
  object-fit: cover;
  background-position: center center !important;
  cursor: pointer;
}
.cat-img-fix {
  width: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  height: 129px;
  object-fit: cover;
}
[dir="rtl"] .cat-img-fix {
  right: -50%;
}
.cat-thumbnail::before {
  content: "";
  left: 0;
  right: 0;
  top: 39%;
  border-radius: 6px;
  bottom: 0;
  z-index: 99;
  position: absolute;
}
.cat-thumbnail::after {
  content: "";
  display: block;
  padding-bottom: 85%;
}
.prod-box-height {
  height: 260px;
  border-radius: 10px;
}
.section-heading {
  font-size: 28px;
}

@media screen and (max-width: 1399px) {
  .prod-box-height .cat-img-fix {
    object-fit: contain;
  }
}
@media screen and (max-width: 991px) {
  .prod-box-height .cat-img-fix {
    object-fit: cover;
  }
}
@media screen and (max-width: 767px) {
  .selling-products-slider .slick-list {
    margin: 0 -10px !important;
  }
  .selling-products-slider .slick-initialized .slick-slide {
    padding: 0px 10px;
  }
  .heading-bb {
    border:0;
  }
  .products {
    margin-top: 15px;
  }
}
@media screen and (max-width: 475px) {
  .prod-box-height {
    height: 220px;
  }
}
@media screen and (max-width: 430px) {
  .prod-box-height {
    height: 200px;
  }
}
@media screen and (max-width: 410px) {
  .prod-box-height {
    /* height: 210px; */
  }
}
@media screen and (max-width: 375px) {
  .prod-box-height {
    /* height: 190px; */
  }
  .mp-home .popular-products-section .grid-product .products {
    width: 152px !important;
  }
  .mp-home .popular-products-section .grid-product {
    gap: 10px;
  }
}
@media screen and (max-width: 360px) {
  .prod-box-height {
    /* height: 160px; */
  }
}
@media screen and (max-width: 340px) {
  .prod-box-height {
    /* height: 160px; */
  }
  .mp-home .popular-products-section .grid-product .products {
    width: 140px !important;
  }
}
@media screen and (max-width: 320px) {
  /* .mp-home .popular-products-section .grid-product .products {
    width: 144px !important;
  } */
}

.thankyou-page .section-heading {
    font-size: 28px;
    line-height: 44px;
}
.thankyou-page .go-to-marketplace {
    padding: 18px 150px;
    margin-top: 50px;
    margin-bottom: 60px;
}
.thankyou-page .mobile-tracking {
    width: 100%;   
}
@media screen and (max-width: 991px) {
    .thankyou-page .mobile-tracking {
        width: 100%;   
    }
    .thankyou-page .section-heading {
        font-size: 22px;
        line-height: 36px;
    }
}
@media screen and (max-width: 575px) {
    .thankyou-page .go-to-marketplace {
        padding: 18px 110px;
    }
}
@media screen and (max-width: 475px) {
    .thankyou-page .go-to-marketplace {
        padding: 18px 70px;
    }
    .thankyou-page .section-heading {
        line-height: 30px;
    }
}
@media screen and (max-width: 375px) {
    .thankyou-page .go-to-marketplace {
        padding: 18px 50px;
    }
}
.brand-section {
  margin: 100px 0 0;
  padding: 100px 0;
  background-color: var(--teal);
  border-radius: 70px 70px 0px 0px;
  color: var(--white);
  margin-bottom: 60px;
}
.brand-section p {
  color: var(--white);
  font-size: 14px;
  font-family: "Light";
  margin-top: 10px;
}
.brand-slider {
  position: absolute;
  bottom: -70px;
  z-index: 9;
  left: 0;
  right: 0;
}
.brand-slider .brand-item {
  width: 200px ;
}
.brand-item {
  /* width: 184px !important; */
  height: 150px;
  border-radius: 10px;
  border: 2px solid var(--teal);
  padding: 10px 16px;
  background: var(--white);
}
.brand-item.with-description {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.brand-slider .slick-initialized .slick-slide {
  margin: 0 10px;
  display: block;
}
.brand-item.without-description {
  display: flex !important;
  flex-direction: column;
  justify-content: space-evenly;
}
.brand-slider div {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  width: 100%;
  /* padding: 40px 20px; */
}
.brand-item p {
  color: var(--black) !important;
  font-family: "Regular";
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  text-transform: capitalize;
}
.core-values-slider .brand-item {
  height: 220px;
  width: 240px;
  flex-shrink: 0;
}
.all-brands-resp {
  display: none;
}

@media screen and (max-width: 840px) {
  .brand-slider .brand-item {
    width: 130px;
}
  .brand-item {
    /* width: 160px !important; */
    height: 130px ;
  }
  .brand-item img {
    width: 90px;
  }
}

@media screen and (max-width: 767px) {
  .all-brands-resp {
    display: block;
    /* position: absolute; */
    /* top: 210px; */
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .mp-home .brand-slider {
    position: static;
  }
}
@media screen and (max-width: 575px) {
  .brand-slider .brand-item {
    border: 1px solid var(--teal);
  }
  .brand-slider .slick-initialized .slick-slide {
    margin: 0 5px;
    padding: 0 5px;
  }
  .core-values-slider .brand-slider .slick-initialized .slick-slide {
    margin: 0 15px;
  }
}
@media screen and (max-width: 500px) {
  /* .all-brands-resp {
    top: 210px;
  } */
}
@media screen and (max-width: 420px) {
  /* .all-brands-resp {
    top: 180px;
  } */
}
@media screen and (max-width: 340px) {
  /* .all-brands-resp {
    top: 170px;
  } */
}

.all-brands-main .products {
    width: 226px;
}
.all-brands-main .selling-products-slider {
    column-gap: 46px;
}
.all-brands-main .prod-box-height img {
    border-radius: 10px;
}
.all-brands-main .brand-detail-section {
    padding-bottom: 3.5rem;
}
.brand-detail-products .grid-product .products {
    width: 19%;
    margin-top: 0;
}
.brand-detail-products .grid-product .products:nth-child(5) {
    margin-top: 0;
}
.brand-detail-products .grid-product .products:nth-child(n+6) {
    margin-top: 20px;
}
.brand-detail-products .grid-filter-row{
    display: none;
}
.bd-long-desc p a{
    color: var(--teal);
}
.brand-detail-products .grid-product {
    gap: 16px;
}
.all-brands-main .grid-filter-row {
    display: none;
}
.brands-details-img img {
    width: 70%;
    margin: auto;
}
@media screen and (max-width: 767px) {
    .brands-details-img {
        order: 1;
    }
    .mp-home .all-brands-main .popular-products-section .grid-product {
        padding-bottom: 0;
    }
    .brands-details-img img {
        width: auto;
        margin: 30px auto;
    }
    .brand-detail-section .bd-long-desc {
        order: 2;
        text-align: center;
    }
}
@media screen and (max-width: 575px) {
    .all-brands-main .popular-products-section {
        margin-top: 0px;
    }
    .brand-detail-section .bd-long-desc .section-description {
        text-align: center;
    }
    .brand-detail-section .bd-long-desc {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .all-brands-main .get-zaraye-app-section {
        display: none;
    }
}
@media screen and (max-width: 420px) {
    .brand-detail-section .bd-long-desc .section-description {
        text-align: start;
    }
    .brand-detail-section .bd-long-desc .section-heading {
        text-align: start;
    }
    .brand-detail-section .bd-long-desc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

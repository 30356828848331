.textile-price-table,
.explore-categories-section {
  margin-top: 120px;
}
.featured-product-section {
  margin-top: 180px;
}
.explore-categories-section {
  margin-bottom: 140px;
}

.industry-landing-page .section-heading {
  font-size: 28px;
  text-transform: capitalize;
  line-height: 36px;
}
.textile-price-table .section-description {
  padding-right: 150px;
}
.category-slider .products {
  margin-top: 0;
}
[dir="rtl"] .textile-price-table .section-description {
  padding-left: 150px;
  padding-right: 0px;
}
.textile-price-table .all-products {
  display: flex;
  justify-content: flex-end;
}
.industry-landing-page .our-customer-section {
  padding: 100px 0;
}
.industry-landing-page .mp-hero-container {
  margin-top: 0;
}
.category-slider .prod-box-height img {
  height: 260px;
}
.industry-popular-product {
  display: flex;
  column-gap: 20px;
  flex-wrap: wrap;
}
.industry-popular-product .products {
  width: 18.77%;
}
.his-content p {
  color: var(--white);
}
.his-content .explore-text {
  margin-bottom: 0px;
  color: var(--white);
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  border-bottom: 1px solid;
  width: max-content;
  line-height: 20px;
}
.explore-text::after {
  position: absolute;
  content: ">>";
  font-family: "SemiBold";
  bottom: 0px;
  padding-left: 6px;
  border-bottom: 1px solid;
}
.our-popular .products {
  width: 100%;
}
.our-popular .slick-slide div:first-child {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.our-popular .slick-slide div:first-child .products {
  width: 100% !important;
}
.selling-products-slider .slick-arrow.slick-prev {
  left: inherit;
  right: 90px;
  top: -90px;
  width: 60px;
}
.selling-products-slider .slick-arrow.slick-next {
  left: inherit;
  right: -2px;
  top: -90px;
  width: 60px;
}
[dir="rtl"] .selling-products-slider .slick-arrow.slick-prev {
  left: inherit;
  left: 0px;
  top: -60px;
  width: 60px;
}
[dir="rtl"] .selling-products-slider .slick-arrow.slick-next {
  left: 90px;
  right: inherit;
  top: -60px;
  width: 60px;
}
@media screen and (max-width: 1199px) {
  .textile-price-table,
  .featured-product-section,
  .explore-categories-section {
    margin-top: 80px;
  }
}
@media screen and (max-width: 1099px) {
  [dir="rtl"] .industry-landing-page .hero-industies-slider-row {
    height: 432px;
  }
}
@media screen and (max-width: 991px) {
  .industry-landing-page .main-form {
    margin-top: 20px;
  }
  .featured-product-section {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .explore-categories-section {
    padding-bottom: 80px;
    margin-bottom: 0;
  }
  .textile-price-table,
  .featured-product-section,
  .explore-categories-section {
    margin-top: 40px;
  }
  .industry-landing-page .our-customer-section {
    padding: 40px 0;
  }
  .industry-landing-page .section-heading {
    text-align: center;
  }
  [dir="rtl"] .industry-landing-page .hero-industies-slider-row,
  .industry-landing-page .hero-industies-slider-row {
    height: 276px;
  }
  .industry-landing-page .his-content {
    bottom: 40px;
  }
  .textile-price-table .section-description {
    text-align: center;
    padding-right: 0;
  }
  [dir="rtl"] .textile-price-table .section-description {
    padding-left: 0;
}
  .explore-categories-section .section-description {
    text-align: center;
  }
  .featured-product-section .column-gap .slick-slide img,
  .explore-categories-section .column-gap .slick-slide img {
    height: auto;
  }
  .industry-landing-page .resp-view-all {
    position: absolute !important;
    bottom: 0;
    width: 220px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 10px 26px;
    border-radius: 30px;
    font-size: 14px;
    background: var(--teal);
    color: var(--white);
    text-decoration: none;
    font-family: "SemiBold";
  }
  .industry-landing-page .selling-products-slider .slick-arrow.slick-next,
  .industry-landing-page .selling-products-slider .slick-arrow.slick-prev {
    left: 0;
    right: 0;
    top: inherit;
    bottom: -30px;
    margin: 0 auto;
  }
  .industry-landing-page .selling-products-slider .slick-arrow.slick-next {
    right: -90px;
  }
  .industry-landing-page .selling-products-slider .slick-arrow.slick-prev {
    left: -90px;
  }
  .industry-landing-page .explore-product-section {
    padding: 0 0 100px;
  }
  .industry-landing-page .zaraye-core-values {
    margin: 80px 0 80px;
  }
  .industry-landing-page .zaraye-core-values .primary-btn {
    padding: 10px 30px;
    font-size: 14px;
  }
  .featured-product-section {
    position: relative;
    margin-top: 20px;
    padding-bottom: 80px;
  }
  .industry-landing-page .get-zaraye-app-section {
    margin-top: 50px;
    padding: 0;
  }
  .industry-landing-page .all-brands-resp {
    top: 180px;
  }
}
@media screen and (max-width: 576px) {
  .industry-landing-page .zaraye-core-values {
    margin: 0 0 40px;
  }
  .industry-landing-page .category-slider .slick-arrow.slick-next,
  .industry-landing-page .category-slider .slick-arrow.slick-prev {
    top: inherit;
    bottom: -80px;
  }
  .industry-landing-page .client-slider .slick-arrow.slick-next,
  .industry-landing-page .client-slider .slick-arrow.slick-prev {
    bottom: -70px;
  }
}
@media screen and (max-width: 425px) {
  .category-slider .prod-box-height {
    border-radius: 10px;
  }
  .category-slider .prod-box-height img {
    height: 200px;
  }
}
@media screen and (max-width: 375px) {
  .category-slider .prod-box-height img {
    height: 160px;
  }
}

.industry-mega-menu {
  display: inline-block;
}
.menu-industry ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: 29.5px;
  padding: 0;
}
.menu-industry ul a {
  width: 23%;
}
.menu-industry ul a:nth-child(n + 5) .menu-industry-li {
  margin-top: 30px;
}
.menu-industry ul .menu-industry-li {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  height: 129px;
}
.menu-industry ul .menu-industry-li::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 64.51%, #000 100%);
  position: absolute;
  border-radius: 15px;
  content: "";
  inset: 0;
}
.menu-industry ul li h3 {
  position: absolute;
  bottom: 4px;
  color: var(--white);
  font-family: "SemiBold";
  left: 16px;
  font-size: 18px;
  z-index: 999;
}
.menu-button::after {
  content: "";
  position: absolute;
  background-image: url("../../../Assests/Images/Common/menu-arrow-down.svg");
  background-repeat: no-repeat;
  top: 69%;
  right: 0;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
}
.polygon-icon {
  position: absolute;
  top: 39px;
  right: -5px;
  transform: translateY(-55.2%);
  z-index: 9999;
}
.polygon-icon img {
  width: 25px;
}
.img-div {
  display: block;
  overflow: hidden;
  background-size: 100% !important;
  object-fit: cover;
  background-position: center center !important;
}
.prod-img-fix {
  width: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  height: 129px;
  object-fit: cover;
}
.img-div::before {
  content: "";
  left: 0;
  right: 0;
  top: 39%;
  border-radius: 6px;
  bottom: 0;
  z-index: 99;
  position: absolute;
}
.img-div::after {
  content: "";
  display: block;
  padding-bottom: 85%;
}
[dir="rtl"] .prod-img-fix {
  right: inherit;
}
@media screen and (max-width: 1399px) {
  .menu-industry ul {
    column-gap: 26.5px;
  }
}
@media screen and (max-width: 1199px) {
  .menu-industry ul {
    column-gap: 21.5px;
  }
}
.seo-landing-pg .main-form {
  padding: 40px;
  border: 2px solid var(--teal);
  border-radius: 40px;
}
.seo-landing-pg .main-form p {
  font-size: 14px;
  line-height: normal;
}
.landing-bg-logo {
  right: 0;
  bottom: -210px;
  z-index: -1;
}
[dir="rtl"] .landing-bg-logo {
  right: inherit;
  left: 0;
  transform: rotateY(180deg);
}
.seo-landing-pg .custom-number-input {
  margin-top: 10px;
  margin-bottom: 8px;
}
.seo-landing-pg .get-zaraye-app-section {
  margin-top: 200px;
}
.seo-landing-pg .main-form input,
.seo-landing-pg .main-form textarea {
  margin-bottom: 8px;
}
.seo-landing-pg .css-jvce2r-control,
.seo-landing-pg .css-eu97x1-control {
  min-height: 47px;
}
[dir="rtl"] .get-quote-img {
  right: inherit;
  top: -90px;
  transform: rotateY(180deg);
  left: 40px;
}
@media screen and (max-width: 1399px) {
  .seo-landing-pg .get-quote-img {
    display: none;
  }
  .seo-landing-pg .get-customize {
    margin-left: 0;
    margin-top: 20px;
  }
  .seo-landing-pg .get-customize {
    margin-left: 20px;
  }
  .seo-landing-pg .explore-box {
    margin-top: 30px;
  }
}

@media screen and (max-width: 991px) {
  .seo-landing-pg .get-customize {
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .seo-landing-pg .get-customize {
    margin-top: 20px;
  }
}
@media screen and (max-width: 576px) {
  .seo-landing-pg .main-form {
    padding: 30px 20px;
  }
  .seo-landing-pg .get-zaraye-app-section {
    margin-top: 50px;
  }
  .landing-bg-logo {
    bottom: 0;
    width: 70vw;
  }
  .landing-bg-logo img {
    width: 100%;
  }
}

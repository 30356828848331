.redefining-sourcing-section,
.collaborative-network-section {
  margin-top: 150px;
}
.redefining-sourcing-section img {
  border-radius: 30px;
}
/* .about-brand-slider-section .section-heading {
  color: var(--white);
} */
/* .zaraye-core-values {
  margin: 100px 0 140px;
  padding: 160px 0 170px;
  background-color: var(--teal);
} */
/* .about-brand-slider-section {
  margin-top: -234px;
} */
/* .about-brand-slider-section .brand-item {
  height: 184px !important;
} */
.core-values-slider .brand-slider {
  bottom: -270px;
  left: 0;
  right: 0;
}
/* .zaraye-core-values h2,
.zaraye-core-values p {
  color: var(--white);
} */
.collaborative-network-section p {
  padding-bottom: 60px;
}
.our-visionaries-section {
  margin-top: 130px;
}
@media screen and (max-width: 767px) {
  .our-visionaries-section {
    margin-top: 60px;
  }
}

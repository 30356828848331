.product-filter-column .ct-list-img {
  width: 26px;
  height: 26px;
}
.product-filter-column .ct-list-title {
  margin-left: 13px;
}
.product-filter-column .collapse:nth-child(n + 2) {
  margin-top: 20px;
}
.filter-sidebar {
  padding: 30px 20px;
  /* position: relative; */
}
.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.category-title {
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-family: "SemiBold";
  padding: 0 0 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
/* .grid-product .products:nth-child(n+5) {
      margin-top: 20px;
  } */
.custom-checkbox-label {
  position: relative;
  display: inline-block;
  width: 17px;
  height: 17px;
  background-color: var(--white);
  border: 1px solid var(--teal);
  border-radius: 4px;
  margin-right: 10px;
}
[dir="rtl"] .custom-checkbox-label {
  margin-right: 0px;
  margin-left: 10px;
}
.custom-checkbox:checked + .custom-checkbox-label {
  background-color: var(--teal);
  border-color: var(--teal);
}

.custom-checkbox:checked + .custom-checkbox-label:after {
  content: "";
  background-image: url("../../../Assests/Images/Common/check-solid.svg");
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -46%);
  color: var(--white);
}

.filter-cont {
  border-radius: 10px;
  border: 1px solid var(--teal);
  border-radius: 10px;
}
.selected-industry-tabs{
  display: none;
}
@media screen and (max-width: 1399px) {
  .product-list-pg .grid-product {
    gap: 19px;
  }
}
@media screen and (max-width: 1199px) {
  .product-list-pg .grid-product {
    gap: 15px;
  }
  .product-list-pg .grid-product .products {
    width: 31.8%;
  }
  .filter-sidebar {
    padding: 20px 12px;
  }
  .product-filter-column {
    padding-right: 0;
  }
  .custom-checkbox-label {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 991px) {
  .product-list-pg .grid-product {
    gap: 9px;
  }
  .product-list-pg .prod-box-height {
    height: 190px;
  }
  .products h5 {
    font-size: 14px;
  }
  .products h6 {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .product-list-pg .grid-product .products {
    width: 31.8%;
  }
  .selected-industry-tabs {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding-left: 10px;
    flex-wrap: wrap;
  }
  .selected-categories {
    /* background: #cccccca1; */
    border-radius: 20px;
    display: flex;
    align-items: center;
    color: var(--Black);
    font-family: "Regular";
    column-gap: 10px;
  }
  .selected-ind {
    background: #cccccca1;
    border-radius: 20px;
    padding: 9px 15px;
    color: var(--Black);
    font-size: 14px;
    font-family: "Regular";
    display: flex;
    align-items: center;
  }
  .selected-ind:nth-child(n+4) {
    margin-top: 10px;
  }
  .selected-ind .close-icon {
    background: none;
    border: 0;
    font-size: 20px;
    line-height: 14px;
    margin-left: 5px;
  }
  .product-list-pg .grid-product {
    margin-top: 15px;
  }
  .product-filter-column .ct-list-img {
    width: 20px;
    height: 20px;
  }
  .industries-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
  }
  /* Initially hidden with height set to 0 and overflow hidden */
  .product-filter-column {
    display: block;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    background: var(--white);
    z-index: 9999999;
    padding: 0;
  }

  .product-filter-column.show {
    height: 90%;
    z-index: 9999999;
    padding: 30px 20px;
    overflow-y: scroll;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0px 14px 19px var(--black);
    background: rgba(255, 255, 255, 0.9);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
  .filter .dropdown-toggle {
    padding: 10px 5px 0;
  }
  .filter-sidebar .collapse-content {
    max-height: 100%;
  }
  .mobile-filter-header {
    font-family: "SemiBold";
    border-bottom: 1px solid rgb(146 146 146 / 20%);
    margin-bottom: 15px;
  }
  .mobile-filter-header h2 {
    margin-bottom: 15px;
  }
  .filter-cont {
    border: 0;
  }
  .filter-sidebar {
    padding: 0;
  }
  .filter-sidebar .ct-list {
    background: #f0f0f0;
    border-radius: 20px;
    padding: 4px 13px;
  }
  .filter-sidebar .selected-filter {
    background: var(--teal);
    /* border-radius: 20px;
    padding: 2px 13px; */
  }
  .filter-sidebar .selected-filter .ct-list-title p {
    color: var(--white);
  }
  .filter-sidebar .custom-checkbox-label {
    display: none;
  }
  .filter-sidebar .collapse-content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
  }
  .close-filter {
    position: absolute;
    right: 30px;
    border: 0;
    top: 30px;
    background: transparent;
    font-family: "Regular";
    z-index: 9999;
  }
  .filter-sidebar .collapse-button {
    pointer-events: none;
    border-bottom: 0;
  }
  .filter-sidebar .collapse .collapse-button::after {
    background-image: none;
  }
  .category-listing-pg .mobile-filter-btn {
    position: static;
    top: 50px;
    background: var(--teal);
    border: 0;
    color: var(--white);
    padding: 6px 18px;
    border-radius: 20px;
    font-family: "Regular";
  }
  .product-filter-column .ct-list-title {
    margin-left: 7px;
  }
}
@media screen and (max-width: 576px) {
  .product-list-pg .grid-product .products {
    width: 48%;
  }
}

p {
  font-size: 16px;
  color: var(--gray-light);
  line-height: 28px;
  font-family: "Regular";
}
[dir ="rtl"] p {
  line-height: 40px;
}

.main-heading {
  font-size: 44px;
  font-family: "SemiBold";
  line-height: 60px;
  text-transform: capitalize;
}

.primary-btn {
  background-color: var(--teal);
  color: var(--white);
  display: inline-block;
  padding: 14px 50px;
  border-radius: 30px;
  text-transform: uppercase;
  border: 0;
  outline: 0;
  font-family: "SemiBold";
}
[dir ="rtl"] .primary-btn {
  padding: 10px 50px 12px;
}
.secondary-btn {
  background-color: var(--white);
  color: var(--teal);
  display: inline-block;
  padding: 14px 50px;
  border-radius: 30px;
  text-transform: uppercase;
  border: 1px solid var(--teal);
  outline: 0;
  font-family: "SemiBold";
}

.o-h {
  overflow: hidden;
}

/* get a quote main form css*/
.get-quote {
  font-size: 24px;
  font-family: "SemiBold";
  color: var(--black);
  text-transform: capitalize;
}

input,
textarea {
  font-family: "Regular";
}

input::placeholder,
textarea::placeholder {
  color: rgb(0 0 0 / 50%) !important;
  font-family: "Regular";
}

.topic-detail-pg h5.blog-published-by {
  color: var(--teal);
  margin-bottom: 0;
}

.main-form {
  padding: 20px;
  border: 1px solid var(--teal);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
}

.iti__country-list {
  width: 100%;
  min-width: 300px;
}

.iti__country-name {
  font-size: 14px;
  display: inline-block;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.iti__dial-code {
  font-size: 14px;
  font-family: "SemiBold";
}

.main-form input:focus,
.main-form textarea:focus,
.price-insights-filters input:focus,
.all-brands-main input:focus {
  border: 1px solid var(--teal);
  box-shadow: none;
}

.main-form input,
.main-form textarea {
  border-radius: 50px;
  border: 1px solid rgb(15 138 140 / 50%);
  padding: 15px 20px;
  font-size: 13px;
  margin-top: 11px;
  /* line-height: normal; */
}

.main-form .primary-btn {
  margin-top: 10px;
}

.get-customize {
  margin-left: 30px;
}
[dir="rtl"] .get-customize {
  margin-left: 0px;
  margin-right: 30px;
}
.cursor-pointer {
  cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.h-80 {
  height: 80%;
}

.personalized-quote-btns-grp {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}

.personalized-quote-btns-grp button:first-child {
  margin-right: -30px;
}

.personalized-quote-btns-grp button:last-child {
  margin-left: -30px;
}

.mp-home .section-heading,
.mp-home .sub-heading {
  font-size: 28px;
}

button {
  transition: all 0.3s;
}

button:active {
  box-shadow: 0px 5px 10px -1px #ccc;
  transform: scale(0.98);
}

.zaraye-app {
  position: relative;
  background-color: var(--teal);
  border-radius: 10px;
  margin-bottom: -100px;
}

.zaraye-app p {
  color: var(--white);
  font-family: "Light";
}

.zaraye-app .main-heading {
  color: var(--white);
  font-size: 28px;
  line-height: 36px;
}
.not-found {
  padding: 100px 0px 50px 0px;
}
.not-found h1 {
  font-family: 'Medium';
  font-size: 40px;
}
.zaraye-app .explore-prod-left-column {
  padding: 70px 120px 70px 0;
}
[dir="rtl"] .zaraye-app .explore-prod-left-column {
  padding: 70px 0px 70px 120px;
}
.zaraye-app .app-logo {
  position: absolute;
  bottom: 0;
  left: 130px;
}

[dir="rtl"] .zaraye-app .app-logo {
  position: absolute;
  bottom: 0;
  right: 130px;
  left: inherit;
}

.zaraye-app .app-logo img {
  width: 100%;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 6px;
  height: 5px;
  background: var(--teal-light);
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--teal);
  border-radius: 10px;
}

.topic-detail-pg p strong {
  font-size: 24px;
}

.topic-detail-pg ul * a {
  color: var(--teal);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.prod-select .css-enac0n-container+label,
.prod-select .css-enac0n-container+label {
  font-size: 11px;
  top: -12px;
  font-family: "Regular";
  z-index: 1;
}

.prod-select label {
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 13px;
  left: 25px;
  font-size: 12px;
  background: var(--white);
  padding: 0 5px;
  color: var(--black);
  transition: all 0.3s ease;
}

[dir="rtl"] .prod-select label {
  left: inherit;
  right: 25px;
}

.css-14dt91g-menu {
  z-index: 99 !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--black) !important;
}

.landing-header-title h1 {
  color: var(--black);
  text-align: center;
  font-size: 28px;
  font-family: "SemiBold";
  line-height: 36px;
}
[dir="rtl"] .greater img{
  transform: rotateY(180deg);
}
[dir="rtl"] .landing-header-title h1{
  line-height: 56px;
}

.sub-header-container {
  margin: 100px 0;
}

.get-zaraye-app-section {
  margin-top: 150px;
}
[dir="rtl"] .get-zaraye-app-section {
  margin-top: 200px;
}

@media screen and (max-width: 1399px) {
  body {
    zoom: 90%;
  }
}

@media screen and (max-width: 1199px) {
  .zaraye-app .app-logo {
    left: 10px;
  }
  [dir="rtl"] .zaraye-app .app-logo {
    right: 10px;
  }
  .zaraye-app .app-logo img {
    width: auto;
  }

  .zaraye-app .explore-prod-left-column {
    padding: 70px 57px 70px 60px;
  }
  [dir="rtl"] .zaraye-app .explore-prod-left-column {
    padding: 70px 60px 70px 57px;
  }
  .zaraye-app .main-heading {
    font-size: 24px;
    line-height: 34px;
  }
}

@media screen and (max-width: 991px) {
  body{
    zoom: 100%;
  }
  .footer-newsletter {
    padding-bottom: 44px;
  }
  .get-zaraye-app-section {
    margin-top: 50px;
  }
  [dir="rtl"] .get-zaraye-app-section {
    margin-top: 0px;
  }
  [dir="rtl"] .zaraye-app .app-logo {
    position: inherit;
  }
  .footer-newsletter .section-heading {
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .zaraye-app .app-logo {
    position: inherit;
  }

  .get-zaraye-app-section .zaraye-app {
    background-color: var(--white);
    margin-bottom: 40px;
  }

  .get-zaraye-app-section.explore-product-section.get-zaraye-app-section {
    margin-top: 0;
  }

  .get-zaraye-app-section .explore-prod-left-column {
    text-align: center;
    order: 1;
    padding: 0 0 30px 0;
  }

  .get-zaraye-app-section .explore-prod-left-column .main-heading {
    color: var(--black);
  }

  .get-zaraye-app-section .explore-prod-left-column p {
    color: var(--gray-light);
  }

  .zaraye-app .img-column {
    order: 2;
  }

  .explore-prod-img-column {
    order: 3;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    padding-top: 30px;
  }

  .explore-prod-img-column .app-images img {
    width: 160px;
  }

  .explore-prod-img-column .app-images {
    text-align: end;
  }

  .explore-prod-img-column .app-images:last-child {
    text-align: start;
  }
}

@media screen and (max-width: 767px) {
  p {
    font-size: 14px;
    line-height: 24px;
  }

  .zaraye-app .app-logo img {
    width: 50%;
  }

  /* .get-zaraye-app-section.explore-product-section.get-zaraye-app-section {
    margin-top: 50px;
  } */
  .get-zaraye-app-section {
    margin-top: 50px;
  }
}

@media screen and (max-width: 575px) {
  .mp-home .section-heading {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
  }

  .sub-header-container {
    margin: 50px 0;
  }

  .get-zaraye-app-section .explore-prod-left-column {
    padding: 0 10px 30px 10px;
  }

  .iti-mobile .iti__country {
    padding: 8px 10px;
  }

  .iti-mobile .iti__country-list {
    width: 320px;
    height: 250px;
    top: inherit;
  }

  .iti-mobile .iti--container {
    top: 50px;
  }

  /* .iti {
    width: initial;
  } */
}

@media screen and (max-width: 420px) {
  .explore-prod-img-column .app-images img {
    width: 120px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
  }
}

[dir="rtl"] * :where(h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  th,
  td,
  a,
  pre,
  li,
  /* button, */
  select,
  .custom-dropdown-icon,
  .menu-button) {
  font-family: "Gulzar" !important;
  letter-spacing: 0.01rem;
}
[dir="rtl"] * button {
  font-family: "Jameel-Regular" !important;
  /* letter-spacing: 0.01rem; */
  font-size: 20px;
}

[dir="rtl"] * :where(h1, h2, h3, h4, h5, h6) {
  font-weight: 700;
}

[dir="rtl"] input::placeholder {
  font-family: "Gulzar" !important;
}

[dir="rtl"] a {
  text-decoration: none !important;
}

[dir="rtl"] pre {
  overflow: hidden;
  text-wrap: balance;
}
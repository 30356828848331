.filter {
  display: flex;
  border: 1px solid rgb(0 128 128 / 50%);
  border-radius: 20px;
  padding: 5px 15px;
}
.filter .dropdown-toggle {
  padding: 10px 5px 0 20px;
}
.sorting .filter .show .dropdown-toggle {
  padding: 2px 5px 0 20px;
}

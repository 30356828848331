.cat-dtl-img {
  width: 300px;
  display: block;
  overflow: hidden;
  background-size: 100% !important;
  object-fit: cover;
  background-position: center center !important;
  position: relative;
}
.cat-dtl-img::before {
  content: "";
  left: 0;
  right: 0;
  top: 39%;
  border-radius: 6px;
  bottom: 0;
  z-index: 99;
  position: absolute;
}
.cat-dtl-img::after {
  content: "";
  display: block;
  padding-bottom: 85%;
}
.cat-img-fix {
  width: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  height: 129px;
  object-fit: cover;
}
.cat-dtl-img-fix {
  width: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  height: 129px;
  object-fit: cover;
}
[dir="rtl"] .cat-dtl-img-fix {
  right: inherit;
}
[dir="rtl"] .cat-img-fix {
  right: -50%;
}
@media screen and (max-width: 1199px) {
  .all-brands-main
    .popular-products-section
    .grid-product
    .products:nth-child(n + 5) {
    display: block;
  }
}

.category-mega-menu {
  display: inline-block;
}
.menu-button {
  color: var(--Black);
  border: none;
  background-color: var(--white);
  cursor: pointer;
  padding: 0;
  position: relative;
  padding-right: 20px;
  font-family: "Regular";
}
[dir="rtl"] .menu-button {
  padding-left: 20px;
  padding-right: 0;
}
[dir="rtl"] .menu-button::after {
  right: inherit;
  left: 0;
}
.menu-content {
  display: none;
  position: absolute;
  top: 44px;
  left: 0;
  background-color: var(--white);
  padding: 40px 60px;
  right: 0;
  z-index: 999;
  border-radius: 10px;
  border: 1px solid var(--teal);
}
.list-open .menu-content {
  display: block;
  margin: 0 10px;
}
.category-menu-column:nth-child(n + 5) .ct-list {
  margin-top: 30px;
}
.ct-list-img {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
}
.ct-list-img img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.column {
  float: left;
  margin-right: 20px;
}
ul {
  list-style-type: none;
  padding: 0;
}

.menu-button::after {
  content: "";
  position: absolute;
  background-image: url("../../../Assests/Images/Common/menu-arrow-down.svg");
  background-repeat: no-repeat;
  top: 69%;
  right: 0;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
}
.polygon-icon {
  position: absolute;
  top: 31.4px;
  right: -5px;
  transform: translateY(-55.2%);
  z-index: 9999;
}
[dir="rtl"] .polygon-icon {
  top: 34px;
  right: inherit;
  left: -5px;
}
.ct-list h2 a {
  text-decoration: none;
  display: block;
  color: var(--Black);
  font-family: "SemiBold";
  font-size: 18px;
}
.menu-category .category-menu-column {
  width: 24%;
  float: left;
}
.ct-list-items {
  margin-top: 18px;
}
.open .menu-content .ct-list-title p {
  font-size: 16px;
}
@media screen and (max-width: 1199px) {
  .menu-content {
    top: 41px;
  }
  .polygon-icon {
    top: 27.4px;
  }
}

@media screen and (max-width: 991px) {
  .menu-button {
    background-color: transparent;
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .ct-list-img img{vertical-align: top;}
}

.footer-section{
  padding: 100px 0;
  border-radius: 100px 100px 0px 0px;
  background-color: var(--white);
  margin-top: 100px;
}
.zaraye-global-pg .footer-section p {
  color: var(--gray-light);
}
.zaraye-market{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.zaraye-market a{
  color: var(--black);
  text-decoration: none;
  font-family: 'SemiBold';
}
.footer-logos{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.footer-logos img{
  width: 182px;
}
.global-footer .global-footer-logo {
  width: 100%;
}
@media screen and (max-width: 1399px) {
  .footer-logos img{
    width: 150px;
  }
}
@media screen and (max-width: 991px) {
  .global-footer .footer-logos img {
    width: 130px;
  }
  .global-footer h4 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .global-footer .global-footer-logo {
    width: 300px;
    display: flex;
    margin: auto;
  }
  .global-footer h4 {
    font-size: 20px;
  }
  .global-footer .global-footer-right {
    margin: auto;
    margin-top: 0;
    margin-bottom: 30px;
  }
  .global-footer p {
    text-align: center;
  }
  .global-footer .footer-logos {
    justify-content: center;
    margin: auto;
    margin-top: 20px;
  }
}

@media screen and (max-width: 575px) {
  .global-footer .global-footer-logo {
    width: 250px;
  }
  .global-footer {
    padding: 60px 0;
    border-radius: 50px 50px 0px 0px;
  }
}
@media screen and (max-width: 475px) {
  
  .global-footer h4 {
    font-size: 16px;
  }
}
@media screen and (max-width: 375px) {
  .global-footer .footer-logos img {
    width: 100px;
  }
}
.sub-heading {
  font-size: 36px;
  font-family: "SemiBold";
}
.live-slider::before {
  position: absolute;
  content: "";
  bottom: 0;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin: 0 auto;
  transition: all 0.3s;
  background-color: var(--green-light);
  top: 0;
  margin: auto;
}
.live-slider::after {
  position: absolute;
  content: "";
  top: 0;
  width: 50px;
  left: 70px;
  height: 2px;
  background-color: var(--green-light);
  bottom: 0;
  margin: auto;
}
.live-slider p {
  color: var(--green-light);
}
.live-slider::before {
  animation: blinking 1.6s infinite;
  position: absolute;
}
.real-time-price td {
  padding: 9px 0 10px 24px !important;
}
.real-time-price th {
  padding: 20px 0px 20px 20px !important;
}
.table-cont {
  overflow-y: auto;
  width: 100%;
}
.table-cont tbody {
  padding-right: 10px;
  white-space: nowrap;
}
.table-cont .first {
  min-width: 300px;
}
.table-cont .second {
  min-width: 170px;
}
.table-cont .last-th {
  min-width: 310px;
}
.table-cont .last-td {
  min-width: 300px;
}
.real-time-price .view-product {
  padding: 6px 35px;
  background-color: var(--white);
  color: var(--teal);
}
.real-time-price tr:nth-child(even) .view-product {
  background-color: var(--teal-light);
  color: var(--teal);
}
.real-time-price .view-product:nth-child(even) button {
  background-color: var(--green-light);
  color: var(--teal);
}
.real-time-price .chat-now {
  padding: 6px 35px;
  background-color: var(--teal);
  color: var(--white);
}
.real-time-price td {
  font-size: 14px;
  vertical-align: middle;
}
.real-time-price td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
[dir="rtl"] .real-time-price td:first-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
[dir="rtl"] .real-time-price td {
  padding: 8px 24px 2px 0px !important;
}
[dir="rtl"] .real-time-price td:last-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 8px 24px 8px 0px !important;
}
[dir="rtl"] .table-cont tbody {
  padding-right: 0px;
  padding-left: 10px;
}
[dir="rtl"] .real-time-price th {
  padding: 20px 20px 20px 0px !important;
}
[dir="rtl"] .all-products {
  width: 240px;
  margin-right: auto;
  margin-left: 12px;
}
.real-time-price td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.real-time-price tr:nth-child(odd) td {
  background-color: var(--teal-light);
}
.all-products {
  color: var(--black);
  margin-left: auto;
  width: 200px;
  font-family: "Regular";
}
.td-updated {
  display: none;
}

@keyframes blinking {
  25% {
    transform: scale(2);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.3;
  }
}
.table-cont tbody {
  display: block;
  max-height: 400px;
  overflow-y: auto !important;
  overflow: hidden;
}
/* .table-cont tbody::-webkit-scrollbar {
  width: 6px;
  background-color: var(--teal-light);
}
.table-cont tbody::-webkit-scrollbar-thumb {
  background-color: var(--teal);
  border-radius: 10px;
} */
.table-cont thead,
.table-cont tbody tr {
  display: table;
  width: 100%;
  font-family: 'Regular';
}
/* .table-cont tr th {
  width: 130px;
}
.table-cont tr td {
  width: 130px;
}
.table-cont .last {
  width: 330px;
} */
.all-products {
  /* margin-right: 12px; */
  margin-top: 1.5rem;
}
[dir ="rtl"] .all-products{
  margin-top: 1.8rem;
}
.real-time-slider .table-cont {
  padding-top: 1.5rem;
}
.real-time-insights-resp {
  display: none;
}
[dir="rtl"] .live-slider p {
  padding-right: 1rem;
}
[dir="rtl"] .live-slider::after {
  right: 70px;
  left: inherit;
}
[dir="rtl"] .explore-product-section .commodity {
  padding-left: 0 !important;
  padding-right: 1rem;
}
.real-time-price .view-product {
  margin-right: 16px;
}
[dir="rtl"] .real-time-price .view-product {
  margin-right: 0;
  margin-left: 16px;
}
@media screen and (max-width: 1400px) {
  .live-slider p {
    font-size: 14px;
  }
  .live-slider::after {
    width: 42px;
  }
}
@media screen and (max-width: 1199px) {
  .real-time-slider .top-header {
    padding: 0px 0.8rem;
    font-size: 11px;
  }
  .live-slider::after {
    width: 38px;
    left: 62px;
  }
}
@media screen and (max-width: 991px) {
  .live-slider::after {
    width: 20px;
    left: 59px;
    top: 2px;
  }
}
@media screen and (max-width: 767px) {
  .real-time-slider {
    flex-direction: column;
  }
  .live-slider::after {
    display: none;
  }
  .live-slider::before {
    margin-left: -40px;
    top: -36px;
  }
  .real-time-slider .commodity {
    width: 100%;
  }
  .real-time-slider .top-header {
    padding: 0px 1rem;
    font-size: 12px;
  }
  .table-cont {
    display: none;
  }
  .real-time-insights-resp {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 12px;
    margin-top: 10px;
  }
  .real-time-insights-resp .insights-items {
    width: 100%;
    padding: 20px 15px;
    /* margin-bottom: 10px; */
    border-radius: 10px;
    font-size: 14px;
  }
  .real-time-insights-resp .insights-items:nth-child(odd) {
    background-color: var(--teal-light);
  }
  .insights-items .product-name {
    font-family: "SemiBold";
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-right: 4px;
  }
  [dir="rtl"] .insights-items .product-name {
    font-family: "Gulzar";
    font-weight: 600;
    letter-spacing: 0.01rem;
  }
  .insights-items .industry-name,
  .insights-items .updated-ago {
    font-family: "Regular";
  }
  [dir="rtl"] .insights-items .industry-name,
  [dir="rtl"] .insights-items .updated-ago {
    font-family: "Gulzar";
    letter-spacing: 0.01rem;
  }
  [dir="rtl"] .insights-items .updated {
    font-weight: 600;
    letter-spacing: 0.01rem;
    font-family: "Gulzar";
  }
  .insights-items img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
    object-fit: cover;
  }
  [dir="rtl"] .insights-items img {
    margin-right: 0px;
    margin-left: 12px;
  }
  .insights-items .view-product {
    padding: 6px 26px;
    background-color: var(--white);
    color: var(--teal);
  }
  [dir="rtl"] .insights-items .view-product {
    margin-left: 8px;
    margin-right: 0 !important;
  }
  .insights-items:nth-child(even) .view-product {
    background-color: var(--teal-light);
    color: var(--teal);
  }
  .insights-items .chat-now {
    padding: 6px 26px;
    background-color: var(--teal);
    color: var(--white);
  }
  .insights-items .updated {
    font-family: "SemiBold";
  }
  [dir="rtl"] .insights-items .chat-now img {
    width: 20px;
    height: 20px;
    margin-bottom: 6px;
  }
}
@media screen and (max-width: 575px) {
  .table-cont tbody tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 10px;
  }

  [dir="rtl"] .all-products {
    margin-right: inherit;
    text-align: left;
    width: 100%;
  }


  /* .real-time-price tr:nth-child(odd) {
    background-color: var(--teal-light);
  }
  .real-time-price tr td {
    border-radius: 10px;
  }
  .real-time-price tr {
    padding: 14px 14px 10px;
  }
  .real-time-price td {
    padding: 6px 0px 10px 0px !important;
  }
  .real-time-price .chat-now,
  .real-time-price .view-product {
    padding: 6px 26px;
  }
  .table-cont .second {
    display: flex;
    justify-content: flex-end;
  }
  .table-cont {
    overflow: hidden;
    padding-top: 0.8rem;
  }
  .table-cont thead {
    display: none;
  }
  .table-cont tbody {
    max-height: none;
    padding-right: 0px;
  }
  .table-cont .last-td {
    margin: 0 auto;
  }
  .table-cont .first,
  .table-cont .second,
  .table-cont .last-td {
    min-width: 50%;
  }
  .table-cont .second:nth-child(2),
  .table-cont .second:nth-child(4) {
    min-width: 42%;
    text-align: right;
  }
  .table-cont .first:first-child {
    font-family: "SemiBold";
  } */
}
@media screen and (max-width: 475px) {
  .all-products {
    margin-right: 0;
    /* margin-top: 0; */
  }
}
@media screen and (max-width: 450px) {
  .real-time-slider .top-header {
    padding: 0px 0.7rem;
    font-size: 11px;
  }
  .insights-items .view-product {
    padding: 6px 22px;
  }
  .insights-items .chat-now {
    padding: 6px 22px;
  }
}
@media screen and (max-width: 350px) {
  .real-time-price .chat-now,
  .real-time-price .view-product {
    padding: 6px 18px;
  }
  .table-cont .last-td {
    padding-bottom: 0 !important;
  }
  .insights-items .view-product {
    padding: 6px 18px;
    font-size: 13px;
  }
  .insights-items .chat-now {
    padding: 6px 18px;
    font-size: 13px;
  }
  .insights-items .product-name {
    font-family: "SemiBold";
    font-size: 14px;
    line-height: 18px;
  }
  .insights-items img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-right: 12px;
    object-fit: cover;
  }
  [dir="rtl"] .insights-items .chat-now img {
    width: 16px;
    height: 16px;
    margin-bottom: 2px;
  }
}

.contactus .main-form {
  padding: 50px;
  border-radius: 40px;
  border: 2px solid var(--teal);
}
.contactus h1 {
  font-size: 28px;
}
/* .contactus .css-1iljrc3-control {
    min-height: 50px !important;
} */
.contactus textarea {
  border-radius: 30px !important;
}
.contactus-left {
  padding: 26px 0px;
}
.contactus-left .section-inner-heading {
  font-size: 24px;
  line-height: 36px;
  font-family: "SemiBold";
}
.contactus-left span {
  font-size: 16px;
  line-height: 28px;
  font-family: "Regular";
  cursor: pointer;
}
.contactus-priority img {
  border-radius: 20px;
}
.contactus-footer {
  margin-top: 150px;
  margin-bottom: 100px;
}
[dir="rtl"] .contactus-left .location {
  font-family: "Gulzar";
}
[dir="rtl"] .contactus textarea {
  font-family: "Gulzar"
}
@media screen and (max-width: 767px) {
  .contactus .main-form {
    padding: 30px;
  }
  .contactus h1 {
    font-size: 22px;
  }
  .contactus-left {
    padding: 15px 0px;
  }
  .contactus-footer {
    margin-top: 20px;
    margin-bottom: 80px;
  }
  .solution-pg-title {
    margin-top: 0;
  }
  .contactus-footer .primary-btn{
    width: 100%;
    text-align: center;
  }
  .contactus-left .section-inner-heading {
    font-size: 18px;
    line-height: 30px;
  }
}

.newsletter .search-bar::before {
    display: none;
}
.newsletter .search-bar input {
    padding-left: 23px;
}
.newsletter .section-heading {
    font-size: 28px;
}
.footer-newsletter {
    padding-top: 40px;
    padding-bottom: 90px;
}
.subscribe-input {
    z-index: 1;
}
.subscribe-input .search-bar {
    padding: 8px;
}
.footer-newsletter .search-bar .primary-btn {
    padding: 13px 40px;
}
@media screen and (max-width: 767px) {
    .footer-newsletter .search-bar{
        flex-wrap: wrap;
        border:0;
    }
    .footer-newsletter {
        padding-top: 0;
    }
    .newsletter .search-bar input::placeholder {
        text-align: center;
    }
    .footer-newsletter .search-bar .primary-btn {
        width:100%;
        padding: 13.5px 40px;
    }
    .footer-newsletter .search-field.form-group {
        width: 100%;
        margin-bottom: 10px;
    }
    .search-field.form-group {
        width: 100%;
        border: 1px solid;
        border-radius: 40px;
        border: 1px solid var(--teal);
        background: var(--white);
        -webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);
        padding: 5px;
    }
}

.search-bar {
  border-radius: 40px;
  border: 1px solid var(--teal);
  background: var(--white);
  backdrop-filter: blur(50px);
  padding: 5px;
  position: relative;
  z-index: 999;
}
.search-bar .primary-btn {
  padding: 8px 40px;
}
[dir="rtl"] .search-bar-container .search-bar .primary-btn {
  font-size: 18px;
}
.d-ltr{
  direction: ltr;
}
.overlay-toggle-menu {
  position: fixed;
  /* top: 80px; */
  bottom: 0;
  left: 100%;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  transition: left 0.5s ease-in-out;
  z-index: 10000;
}
.overlay-toggle-menu.open {
  left: 0;
  /* top: 80px; */
}
.backdrop {
  position: absolute;
  right: 0;
  bottom: 0;
}
.search {
  width: 260px;
}
.search input {
  border-radius: 50px !important;
  border: 1px solid var(--teal);
  height: 40px;
  background: #ffffff4a;
  backdrop-filter: blur(50px);
  font-size: 14px;
  color: var(--white);
  font-family: "Regular";
  transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out;
}
.search input::placeholder {
  color: white;
}
.search input:focus {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--white);
  border: 0;
  outline: 0;
  box-shadow: none;
  font-family: "Regular";
}
.search button {
  z-index: 10;
  height: 40px;
}
.search button img {
  padding: 9px 18px;
}
.close {
  font-size: 18px;
  font-family: "Regular";
  line-height: 28px;
}
.footer-address {
  color: var(--black);
  padding: 20px 0px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}
.footer-address span {
  line-height: 24px;
  font-family: "Regular";
}

[dir="rtl"] .footer-address span {
  font-family: "Gulzar";
}
.z-20 {
  z-index: 20;
}
.socials-links a {
  color: var(--white);
  font-family: "Light";
}
.search-options {
  position: absolute;
  top: 54px;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 15px 20px 25px 25px;
  border-radius: 10px;
  border: 1px solid var(--teal);
  margin: 0 10px;
  z-index: 10;
}
.search-options p {
  font-size: 13px;
  line-height: 20px;
  color: var(--gray-light);
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  min-height: 24px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}
.search-list {
  height: auto;
  overflow-y: auto;
  max-height: 380px;
}
.search-options .ct-list-title {
  width: 86.5%;
}
.search-options .ct-list-title h6 {
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
  font-family: "SemiBold";
}
.search-list-img {
  display: block;
  overflow: hidden;
  background-size: 100% !important;
  object-fit: cover;
  background-position: center center !important;
  position: relative;
  height: 75px;
  width: 11%;
  border-radius: 10px;
}
.search-list-img::before {
  content: "";
  left: 0;
  right: 0;
  top: 39%;
  border-radius: 6px;
  bottom: 0;
  z-index: 99;
  position: absolute;
}
.search-list-img::after {
  content: "";
  display: block;
  padding-bottom: 85%;
}
.search-img-fix {
  width: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  height: 70px;
  object-fit: cover;
}

.search-options .ct-list-items:last-child {
  border-bottom: 0;
}
.search-options .ct-list-items {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;
  border-bottom: 1px solid #0f8a8c29;
  padding: 10px 0;
  margin-right: 15px;
}
.zi-1 {
  z-index: -1;
}
.search-bar::before {
  content: "";
  background-image: url("../../../Assests/Images/MarketPlace/SearchBefore.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  inset: 18px 10px;
  margin-top: -1px;
}
.search-bar input {
  padding-left: 35px;
}
.search-bar input:focus {
  box-shadow: none !important;
  border: 0 !important;
}
.search-bar input::placeholder {
  color: var(--gray-light);
  font-size: 14px;
}
.search-field.form-group {
  width: 65%;
}
.help-language {
  /* width: 70%; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.langu-help {
  width: 80%;
}
.help-language .help {
  /* width: 40%; */
  text-align: end;
}
.help-language .language {
  /* width: 70%; */
  text-align: end;
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
}
.cart-favourite {
  /* width: 26%; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.mp-header-section {
  padding: 20px 0;
  /* margin-top: 10px; */
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
nav.header-nav .header-nav-ul {
  list-style: none;
  display: flex;
  align-items: center;
  column-gap: 25px;
  margin-bottom: 0;
  padding-left: 0;
}
nav.header-nav .header-nav-ul li .header-nav-link {
  color: var(--Black);
  text-decoration: none;
  font-family: "Regular";
  width: 23.1%;
}
nav.header-nav .header-nav-ul li .header-nav-link img {
  width: 100%;
}
nav.header-nav .header-nav-ul li .custom-dropdown-icon {
  padding: 0;
}
.header-menu-btns button {
  padding: 9px 29px;
  font-size: 14px;
}
[dir="rtl"] .header-menu-btns button {
  font-size: 18px;
  font-weight: 600;
}
.header-menu-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px 0;
}
.language .custom-dropdown .dropdown-menu.show {
  min-width: 144px;
  transform: translate(-103px, 46px) !important;
}
.language  .custom-dropdown .lang-options.dropdown-menu.show {
  min-width: 104px;
  transform: translate(-3px, 46px) !important;
}
.language .dropdown-menu::after {
  left: inherit;
  right: 13px;
  margin: inherit;
  margin-left: auto;
}
.offcanvas {
  width: 500px !important;
  z-index: 9999 !important;
}
.cart-canvas .offcanvas-header {
  padding: 50px 48px 20px 48px !important;
}
.cart-canvas .offcanvas-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
}
.cart-listings {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 14px 10px 20px !important;
}
.cart-btns {
  padding: 30px !important;
}
.cart-canvas .section-heading {
  font-size: 28px;
}
.cart-canvas .cart-img {
  width: 75px;
  height: 75px;
}
.cart-canvas .cart-list-detail .section-heading {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}
.cart-canvas .offcanvas-body .cart-list:nth-child(odd) {
  background-color: transparent;
}
.view-cart {
  background-color: var(--white) !important;
  border: 1px solid var(--teal) !important;
  color: var(--teal) !important;
}
.cart-list-detail .form-item input,
.cart-list-detail .form-item select {
  height: 40px;
  border: 0px;
}
.cart-list-detail .qtyField input {
  padding: 0 20px;
}
.cart-listings .form-item {
  margin-bottom: 0;
}
.cart-count {
  position: absolute;
  right: 7px;
  background: #c62222;
  border-radius: 50px;
  width: 14px;
  height: 14px;
  top: 9px;
  font-size: 9px;
  text-align: center;
  line-height: 15px;
  font-family: "Medium";
  color: var(--white);
}
[dir="rtl"] .cart-count {
  right: 17px;
}
.qty-meter {
  font-size: 1rem;
  font-family: "SemiBold";
}
.attribute-names {
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
}
.attribute-names span {
  font-size: 14px;
  line-height: 24px;
  font-family: "Regular";
}
.offcanvas-body .cart-list:nth-child(even) {
  background-color: var(--teal-light);
  padding: 14px 14px 10px 14px;
}
.offcanvas-body .cart-list {
  padding: 14px 14px 10px 14px;
}
.third-menu {
  display: flex;
  justify-content: space-between;
}
.responsive-container {
  display: none;
}
.sidebar {
  padding: 30px 0px;
}
.responsive-container svg {
  fill: var(--gray-light);
}
.toggle-cart .card-is-empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.menu-icon {
  display: none;
}
.search-bar-container {
  position: relative;
  cursor: pointer;
}
[dir="rtl"] .search-bar-container {
  direction: rtl;
}
[dir="rtl"] .header-menu-container .primary-btn {
  margin-right: 12px;
}
[dir="rtl"] .search-bar input {
  padding-right: 40px;
}
[dir="rtl"] .help-language .language,
[dir="rtl"] .help-language .help {
  text-align: justify;
}
[dir="rtl"] .cart-favourite {
  justify-content: flex-start;
}
[dir="rtl"] .custom-offcanvas-header span {
  font-family: "Gulzar";
  padding-left: 12px;
}
.footer-address a img {
  margin: 0 8px;
}
@media screen and (max-width: 1459px) {
  .language .custom-dropdown .dropdown-menu.show {
    transform: translate(35px, 46px) !important;
  }
}
@media screen and (max-width: 1400px) {
  .header-menu-btns button {
    font-size: 13px;
    padding: 9px 24px;
  }
  .help-language {
    justify-content: center;
  }
  .mp-header-section .zaraye-logo {
    width: 140px;
  }
}
@media screen and (max-width: 1199px) {
  .search-bar .primary-btn {
    padding: 9px 32px;
    font-size: 14px;
  }
  .header-menu-btns button {
    font-size: 11.5px;
    padding: 9px 16px;
  }
  .help-language {
    justify-content: flex-end;
  }
  nav.header-nav .header-nav-ul {
    column-gap: 20px;
  }
  nav.header-nav .header-nav-ul li .header-nav-link {
    font-size: 14px;
  }
  .menu-button {
    font-size: 14px;
  }
  .menu-button::after {
    top: 64%;
    right: 3px;
    width: 12px;
    height: 13px;
  }
  .search-field.form-group {
    width: 55%;
  }
  /* .cart-favourite {
    width: 100%;
  } */
}
@media screen and (max-width: 991px) {
  .third-menu {
    justify-content: flex-end;
  }
  [dir="rtl"] .third-menu {
    justify-content: flex-start;
  }
  nav.header-nav .header-nav-ul {
    align-items: start;
  }
  .header-menu-btns button {
    font-size: 14px;
    padding: 9px 30px;
  }
  .header-nav-ul li {
    margin-bottom: 15px;
    width: 100%;
  }
  nav.header-nav {
    margin-top: 30px;
  }
  nav.header-nav .header-nav-ul li .header-nav-link {
    font-size: 16px;
  }
  .menu-footer {
    justify-content: space-between;
    height: 100%;
    /* height: calc(100vh - 80px); */
  }
  .mp-header-section .zaraye-logo {
    width: 150px;
  }
  .search-resp {
    margin-right: 20px;
  }
  [dir="rtl"] .search-resp {
    margin-left: 20px;
  }
  .cart-favourite a {
    margin-right: 20px;
  }
  [dir="rtl"] .responsive-hamburger {
    margin-right: 10px;
  }
  [dir="rtl"] .cart-favourite a {
    margin-right: -12px;
    /* margin-left: 20px; */
  }
  .footer-address div a {
    margin-right: 0px;
  }
  .cart-favourite .cart-count {
    left: -36px;
    top: -1px;
  }
  [dir="rtl"] .cart-favourite .cart-count {
    right: -7px;
  }
  .sidebar {
    padding: 30px 0px;
  }
  .overlay-toggle-menu .sidebar-container {
    /* background-color: var(--white); */
    /* height: 100vh; */
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .responsive-menu .polygon-icon {
    display: none;
  }
  .responsive-menu .menu-industry ul {
    flex-direction: column;
  }
  .responsive-menu .menu-content {
    position: static;
  }
  .industry-mega-menu .menu-button,
  .category-mega-menu .menu-button {
    font-size: 16px;
  }
  .industry-mega-menu,
  .category-mega-menu {
    width: 100%;
  }
  .store-icons img {
    padding-right: 20px;
  }
  .overlay-toggle-menu {
    height: calc(100vh - 113px);
  }
  /* hamburger with close icon */
  .menu-icon {
    width: 24px;
    height: 18px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .bar {
    width: 24px;
    height: 2px;
    background-color: var(--black);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  .bar1 {
    transform: translateY(0) rotate(0deg);
  }
  .bar2 {
    transform: translateY(0px) rotate(0deg);
  }
  .bar3 {
    transform: translateY(0px) rotate(0deg);
  }
  .menu-icon.open .bar1 {
    transform: translateY(9px) rotate(40deg);
  }
  .menu-icon.open .bar2 {
    transform: translateY(6px) rotate(45deg);
    display: none;
  }
  .menu-icon.open .bar3 {
    transform: translateY(-7px) rotate(-41deg);
  }

  .w-full-resp {
    padding-right: 6px;
  }
  /* .overlay-toggle-menu {
    height: calc(100vh - 70px);
  } */
  .sidebar {
    padding: 30px 20px;
  }
  .responsive-menu .list-open .menu-content {
    background: transparent;
    padding: 15px 0 0 15px;
    margin: 0;
    border: 0;
    display: flex;
  }
  .menu-category .category-menu-column {
    width: 100%;
  }
  .menu-category .category-menu-column .ct-list .ct-list-items {
    margin-left: 10px;
  }
  .menu-category .category-menu-column .ct-list {
    font-size: 0;
  }
  .category-menu-column:nth-child(n + 5) .ct-list {
    margin-top: 0;
  }
  .menu-industry ul a {
    width: 100%;
    text-decoration: none;
  }
  .header-nav-ul .prod-img-fix {
    position: static;
    height: 25px;
    transform: none;
    max-width: 100%;
  }
  .ct-list-img {
    width: 25px;
    height: 25px;
  }
  .ct-list-items {
    margin-top: 0;
    margin-bottom: 15px;
  }
  .industry-mega-menu .img-div {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
  .menu-industry ul .menu-industry-li::after {
    content: none;
  }
  .menu-industry ul .menu-industry-li .img-div::before {
    content: none;
  }
  .menu-industry ul li h3 {
    color: var(--black);
    position: static;
    margin-left: 15px;
    font-size: 16px;
    margin-bottom: 0;
    font-family: "Regular";
  }
  [dir="rtl"] .footer-address a img {
    margin: 0 10px;
  }
  [dir="rtl"] .menu-industry ul li h3 {
    margin-right: 15px;
  }
  [dir="rtl"] .store-icons img:first-child {
    padding-right: 0;
  }
  [dir="rtl"] .header-menu-btns button:nth-child(2) {
    margin-right: 1rem;
    font-size: 18px;
  }
  [dir="rtl"] .header-menu-btns button:nth-child(1) {
    margin-right: 0 !important;
  }
  [dir="rtl"] .header-nav-link {
    margin-right: 3px !important;
  }
  .menu-industry ul a:nth-child(n + 5) .menu-industry-li {
    margin-top: 0;
  }
  .menu-industry ul .menu-industry-li {
    height: auto;
    display: flex;
    align-items: center;
  }
  .header-menu-btns {
    justify-content: flex-start;
  }
  .fixed-header {
    position: fixed;
    top: -2px;
    left: 0;
    width: 100%;
    background-color: var(--white);
    z-index: 1000;
    transition: transform 0.3s ease;
  }
  .fixed-header.hidden {
    transform: translateY(-100%);
    transition: transform 0.4s ease;
  }
  .resp-search {
    position: absolute;
    right: 0;
    top: -8px;
    z-index: 10;
    padding: 0px 10px;
    width: 100%;
  }
  .resp-search input {
    width: 100%;
    border: 1px solid var(--teal);
    background-color: var(--white);
    height: 52px;
    border-radius: 40px;
  }
  .resp-search .form-control:focus {
    box-shadow: none;
    border-color: transparent;
    border: 1px solid var(--teal);
  }
  .resp-search .close-search-icon button {
    background-color: transparent;
    border: 0;
  }
  .resp-search .close-search-icon {
    cursor: pointer;
  }
  .resp-search .close-search-icon img {
    cursor: pointer;
  }
  .resp-search .close-search-icon {
    position: absolute;
    top: 6px;
    right: 16px;
    width: 50px;
    height: 40px;
    border-radius: 4px 40px 40px 4px;
    text-align: center;
    background-color: rgba(15, 138, 140, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search-bar-container {
    position: static;
  }
  .w-full-resp {
    position: relative;
  }
  .resp-search input::placeholder {
    font-size: 14px;
  }
  .offcanvas {
    width: 400px !important;
  }
  .card-is-empty img {
    width: 180px;
  }
  .custom-offcanvas-header img {
    width: 14px;
  }
  .custom-offcanvas-header span {
    font-size: 16px;
  }
  [dir="rtl"] .custom-offcanvas-header span {
    padding-left: 12px;
  }
  [dir="rtl"] .resp-search .close-search-icon {
    left: 16px;
    right: initial;
    border-radius: 40px 4px 4px 40px;
  }
  [dir="rtl"] .resp-search .close-search-icon button {
    font-size: 0;
  }
  .cart-listings {
    padding: 0 !important;
  }
  .cart-canvas .offcanvas-header {
    padding: 30px 22px 20px 24px !important;
  }
  .cart-btns {
    padding: 0 0 20px 0 !important;
  }
  .cart-details img {
    width: 20px;
  }
  .decrease-qty img,
  .increase-qty img {
    width: 26px;
  }
  .cart-list-detail .qtyField input {
    font-size: 14px;
  }
  .cart-canvas .section-heading {
    font-size: 24px;
  }
}
@media screen and (max-width: 767px) {
  .search-resp {
    margin-right: 30px;
  }
  .cart-favourite .cart-count {
    left: -3px;
  }
  /* .overlay-toggle-menu {
    height: calc(100vh - 113px);
  } */

  .search-options {
    padding: 15px 12px 12px 16px;
  }
  /* .resp-search .close-search-icon {
    top: 6px;
  } */
  .search-list-img {
    width: 15%;
  }
  .search-options .ct-list-title {
    width: 82%;
  }
}
@media screen and (max-width: 575px) {
  .search-resp {
    margin-right: -2px;
  }
  [dir="rtl"] .responsive-container svg {
    margin-bottom: 12px;
  }
  [dir="rtl"] .responsive-hamburger {
    margin-right: 0px;
  }
  [dir="rtl"] .cart-favourite {
    /* padding-right: 0; */
  }
  .responsive-container {
    position: fixed;
    bottom: -2px;
    background: var(--white);
    box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  .overlay-toggle-menu {
    height: calc(100vh - 77px);
  }
  .responsive-container span {
    font-size: 13px;
    line-height: normal;
    padding-top: 3px;
    color: var(--gray-light);
  }
  .responsive-container a {
    text-decoration: none;
    color: var(--gray-light);
    font-family: "Regular";
  }
  .responsive-container .cart-count {
    position: absolute;
    /* right: -7px; */
    top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 12px;
    padding-bottom: 3px;
    width: 15px;
    height: 15px;
  }
  .menu-footer {
    padding: 0px 20px;
  }
  .responsive-container .active {
    color: var(--teal);
    font-family: "SemiBold";
  }
  .responsive-container .active svg {
    fill: var(--teal);
    stroke: var(--teal);
  }
  .search-list {
    max-height: 320px;
  }
  .search-options .ct-list-items {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 475px) {
  .responsive-container .cart-count {
    right: 0px;
  }
  .responsive-container a {
    font-size: 13px;
  }
  .responsive-container a svg {
    width: 30px;
  }
  .search-list-img {
    width: 18%;
  }
  .search-options .ct-list-title {
    width: 80%;
  }
  [dir="rtl"] .responsive-container svg {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 425px) {
  .search-resp {
    margin-right: 10px;
  }
  .header-menu-btns button {
    padding: 9px 20px;
  }
  .responsive-container {
    height: 80px;
  }
  .search-list-img {
    width: 18%;
    height: 64px;
  }
  .search-options .ct-list-title {
    width: 79%;
  }
}
@media screen and (max-width: 390px) {
  .header-menu-btns button {
    font-size: 13px;
    padding: 9px 16px;
  }
  .header-menu-btns {
    justify-content: space-between;
  }
  .responsive-container .cart-count {
    right: -3px;
  }
  [dir="rtl"] .cart-favourite {
    padding-right: 0;
  }
  .header-menu-btns button {
    font-size: 13px;
    padding: 9px 15px;
  }
  /* .store-icons img {
    padding-right: 10px;
  } */
}
@media screen and (max-width: 375px) {
  .w-full-resp {
    padding-right: 6px;
  }
  .menu-footer {
    padding: 0px 2px;
  }
  .search-list-img {
    width: 20%;
    height: 60px;
  }
  .search-options .ct-list-title {
    width: 77%;
  }
}
@media screen and (max-width: 360px) {
  .header-menu-btns button {
    font-size: 12px;
    padding: 9px 14px;
  }
  .responsive-container .cart-count {
    right: -6px;
  }
  .store-icons img {
    width: 140px;
  }
  .responsive-container {
    width: 100%;
  }
  /* .overlay-toggle-menu {
    height: calc(100vh - 70px);
  } */
}
@media screen and (max-width: 346px) {
  .header-menu-btns button {
    font-size: 11px;
    padding: 9px 14px;
  }
}
@media screen and (max-width: 330px) {
  .header-menu-btns button {
    padding: 9px 12px;
  }
  .w-full-resp {
    /* width: 100%; */
    padding-right: 14px;
  }
  .search-list-img {
    width: 20%;
    height: 50px;
  }
  .search-options .ct-list-title {
    width: 77%;
  }
  .search-options .ct-list-title h6 {
    font-size: 13px;
  }
  .search-options p {
    font-size: 12px;
    line-height: 14px;
  }
}
@media screen and (max-width: 320px) {
  .w-full-resp {
    padding-right: 6px;
    width: auto;
  }
}

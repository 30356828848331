.navigate-intricacies-section {
  background-color: var(--teal);
  border-radius: 70px 70px 0px 0px;
  padding-bottom: 200px;
}
.global-hero-section .get-quote-img {
  display: none;
}
.global-header-back-drop-logo {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
[dir="rtl"] .global-header-back-drop-logo{
  left: inherit;
  right: 0;
  transform: rotateY(180deg);
}
.global-hero-section::before {
  background-image: url("../../../Assests/Images/Global/global-hero-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  height: 100vh;
  width: 100%;
  content: "";
}
[dir="rtl"] .global-hero-section::before {
  transform: rotateY(180deg);
}
.global-hero-section {
  height: 100vh;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  flex-wrap: wrap;
}
.global-hero-section .main-heading,
.global-hero-section p,
.global-hero-section .get-customize a {
  color: var(--white);
}
.global-quote-form {
  padding: 40px;
  border: 2px solid var(--teal);
  border-radius: 28px;
  background: var(--teal);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.global-quote-form h1,
.global-quote-form p {
  color: var(--white);
}
.global-quote-form .form-control {
  height: 60px;
}
.global-quote-form .custom-number-input {
  margin-top: 10px;
}
.global-quote-form .custom-number-input .iti__selected-dial-code {
  color: #ffffff78;
  font-family: "Regular";
}
.iti {
  width: 100%;
}
.global-quote-form .iti__arrow {
  border-top: 4px solid #ffffff78;
}
.global-quote-form input,
.global-quote-form .css-13cymwt-control,
.global-quote-form .css-t3ipsp-control {
  border-radius: 50px;
  border: 1px solid var(--teal);
  background: #ffffff2e;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  padding: 0 21px;
  /* padding: 21px 21px; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  color: var(--white);
}
.global-quote-form input:is(:focus),
.global-quote-form .css-t3ipsp-control:is(:focus) {
  border: 1px solid var(--teal);
  box-shadow: none;
  background: #ffffff2e;
  color: var(--white);
}
.global-quote-form .css-1xc3v61-indicatorContainer:hover {
  color: #ffffff78;
}
.global-quote-form .css-13cymwt-control:hover {
  border-color: hsl(180.96deg 80.65% 30.39%);
}
.global-quote-form .css-1dimb5e-singleValue,
.global-quote-form .css-1jqq78o-placeholder {
  color: #ffffff78;
}
.global-quote-form input::placeholder {
  color: #ffffff78 !important;
}
.global-quote-form button {
  margin-top: 20px;
  padding: 16.5px 50px;
}
[dir ="rtl"] .global-quote-form button {
  padding: 12px 50px 16px;
}
.global-quote-form .css-13cymwt-control,
.global-quote-form .css-t3ipsp-control {
  padding: 11px 20px;
}
.quote-form-section {
  margin-top: -120px;
}

.about-zaraye-global {
  margin-top: 100px;
  margin-bottom: 450px;
}
.navigate-intricacies-row {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 90px;
}
.global-network-section .welcome,
.global-network-section .get-quote-img {
  display: none;
}
.global-network-section {
  align-items: center;
  margin-top: -100px;
}
.global-network-section .main-heading {
  margin-bottom: 20px;
  margin-top: 60px;
}
.global-network-section .primary-btn {
  margin-top: 50px;
}

.scroll-down {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  width: 40px;
  border-radius: 30px;
}

.scroll-down span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
  margin-left: 9px;
}

.scroll-down span:nth-child(2) {
  animation-delay: -0.2s;
}
.down {
  width: 200px;
  position: absolute;
  bottom: 160px;
  left: 0;
  right: 0;
  margin: auto;
}
.scroll-down span:nth-child(3) {
  animation-delay: -0.4s;
}

/* .css-mohuvp-dummyInput-DummyInput {
  padding: 0 !important;
}
.mp-home .css-mohuvp-dummyInput-DummyInput {
  padding: 0 !important;
} */
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
.navigate-global-section {
  background-color: var(--teal);
  border-radius: 100px 100px 0px 0px;
  position: relative;
}
.navigate-global-section .main-heading,
.navigate-global-section p {
  color: var(--white);
}
.navigate-global-section .primary-btn {
  border: 1px solid;
}
.before-footer-back-drop-logo {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 100px;
}
.before-footer-back-drop-logo img {
  width: 300px;
}
@media screen and (max-width: 1399px) {
  .global-network-section img {
    width: 100%;
  }
  .global-hero-section .explore-box {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .global-network-section .primary-btn {
    margin-top: 30px;
  }
  .global-network-section .main-heading {
    margin-top: 20px;
  }
  .zaraye-global-pg .get-zaraye-app-section .zaraye-app {
    background-color: transparent;
  }
  .about-zaraye-global {
    margin-bottom: 370px;
  }
}
@media screen and (max-width: 991px) {
  .global-hero-section .main-heading,
  .about-zaraye-global .main-heading,
  .navigate-intricacies-section .main-heading,
  .global-network-section .main-heading {
    font-size: 28px;
    line-height: 36px;
  }
  .zaraye-global-pg .inner-layout {
    margin-top: 0;
  }
  .about-zaraye-global {
    margin-bottom: 350px;
  }
  .before-footer-back-drop-logo {
    top: inherit;
    bottom: 380px;
  }
  .before-footer-back-drop-logo img {
    width: 220px;
  }
  .zaraye-global-pg .navigate-intricacies-section {
    padding-bottom: 160px;
  }
  .vid-container video {
    height: 350px;
  }
  .zaraye-global-pg .get-zaraye-app-section .explore-prod-left-column p,
  .zaraye-global-pg
    .get-zaraye-app-section
    .explore-prod-left-column
    .main-heading {
    color: var(--white);
  }
}
@media screen and (max-width: 767px) {
  .global-network-section {
    text-align: center;
    margin-bottom: 0;
  }
  .about-zaraye-global {
    margin-top: 50px;
  }
  .global-network-section .main-heading {
    margin-top: 40px;
  }
}
@media screen and (max-width: 575px) {
  .global-hero-section .explore-box {
    justify-content: center !important;
  }
  .zaraye-global-pg .vid-container video {
    height: 260px;
  }
  .zaraye-global-pg .video-column {
    margin-top: -200px;
  }
  .about-zaraye-global {
    margin-bottom: 240px;
  }
  .zaraye-global-pg .get-zaraye-app-section {
    padding-top: 50px;
  }
  .zaraye-global-pg .navigate-global-section {
    border-radius: 15px 15px 0px 0px;
  }
  .zaraye-global-pg .scroll-down {
    display: none;
  }
}
@media screen and (max-width: 475px) {
  .global-header-back-drop-logo img {
    display: none;
  }
  .global-hero-section .main-heading,
  .about-zaraye-global .main-heading,
  .navigate-intricacies-section .main-heading,
  .global-network-section .main-heading {
    font-size: 22px;
    line-height: 30px;
  }
  .before-footer-back-drop-logo {
    bottom: 300px;
  }
  .before-footer-back-drop-logo img {
    width: 160px;
  }
}
@media screen and (max-width: 420px) {
  .global-quote-form button {
    padding: 14px 30px;
  }
  .global-quote-form {
    padding: 30px;
  }
  .zaraye-global-pg .vid-container video {
    height: 210px;
  }
  .zaraye-global-pg .video-column {
    margin-top: -160px;
  }
  .zaraye-global-pg .about-zaraye-global {
    margin-bottom: 200px;
  }
  .global-quote-form h1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 340px) {
  .global-quote-form {
    padding: 20px;
  }
}

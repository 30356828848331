.modal-content {
    border-radius: 10px !important;
    border: 0;
    padding: 35px;
}
.request-headings .section-description {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
}
.request-headings .section-heading {
    text-align: center;
    font-size: 24px;
    line-height: 36px;
}
.lang-flag{
    margin-right: 8px;
    width: 20px;
    height: auto;
}
[dir="rtl"] .lang-flag{
    /* margin-right: 0;
    margin-left: 8px; */
}
[dir="rtl"] .request-headings .section-heading,
[dir="rtl"] .request-headings p {
    text-align: right;
}
.modal-content .main-form {
    border: 0;
    position: relative;
}
.main-form .modal-close {
    position: absolute;
    top: -6px;
    right: 0;
    z-index: 99;
    /* padding-bottom: 1.5rem; */
}
.mdl-prod{
    /* height: 260px; */
    position: relative;
    overflow: hidden;
}
.mdl-prod-img{
    min-width: 100%;
    min-height: 100%;
    max-width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    /* height: 129px; */
    object-fit: cover;
}
.request-modal .main-form input {
    text-align: center !important;
}
.modal-image img {
    object-fit: cover;
    border-radius: 10px;
}
.form-control:disabled {
    background-color: var(--white);
}
.main-form .modal-image {
    display: flex;
    align-items: center;
    justify-content: center;
}
.resp-apps-icons {
    justify-content: center;
}
.main-form .modal-image img {
    /* width: 100%; */
}
@media screen and (max-width: 991px) {
    .main-form .modal-image img {
        width: 300px;
    }
}
@media screen and (max-width: 767px) {
    .main-form .modal-image img {
        width: 220px;
    }
    .request-headings .section-heading {
        font-size: 20px;
        line-height: 28px;
    }
    .resp-apps-icons a img {
        width: 150px;
    }

    .resp-apps-icons {
        justify-content: space-evenly;
    }
}
@media screen and (max-width: 575px) {
    .modal.show .modal-dialog {
        margin-bottom: 120px;
    }
}
@media screen and (max-width: 420px) {
    .request-headings .section-heading {
        font-size: 18px;
        line-height: 28px;
    }
    .modal-content {
        padding: 20px;
    }
    .main-form .modal-close {
        top: 4px;
        z-index: 1;
    }
    .resp-apps-icons a img {
        width: 100px;
    }
}

.explore p a {
  position: relative;
  padding-right: 20px;
  text-decoration: none;
  transition: 0.3s;
  font-size: 14px;
  color: var(--black);
}
[dir="rtl"] .explore p a {
  padding-right: 0;
  padding-left: 20px;
  font-size: 16px;
}
.explore p a::after {
  position: absolute;
  content: ">>";
  width: 100%;
  height: 20px;
  top: -3px;
  right: 0;
  font-family: "Regular";
  border-bottom: 1px solid;
  text-align: right;
  vertical-align: middle;
}
[dir="rtl"] p a::after {
  right: inherit;
  left: 0;
  text-align: left;
  top: 9px;
  padding-bottom: 25px;
  border-bottom: 0;
}

.ct-list-title p {
  color: var(--black);
  font-size: 14px;
}
[dir="rtl"] .ct-list-title p {
  font-size: 16px;
  line-height: 30px;
}

.explore p a:hover {
  position: relative;
  padding-right: 25px;
  transition: 0.3s;
}
[dir="rtl"] .explore p a:hover {
  padding-left: 30px;
  padding-right: 0;
  transition: 0.3s;
}
.categories-list-inner {
  direction: ltr;
}

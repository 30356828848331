.pagination-box {
  margin: 15px 0;
}
ul.pagination {
  font-family: "Regular";
  text-transform: uppercase;
}
ul.pagination li a {
  color: var(--gray-light);
  padding: 0 15px;
  text-decoration: none;
  font-size: 16px;
  transition: 0.3s;
}
ul.pagination li a:hover {
  color: var(--black);
  transition: 0.3s;
}
.show {
  display: flex;
  align-items: center;
}
.hide {
  display: none;
}
/* ul.pagination .page-prev{
        position: relative;
    } */
/* ul.pagination .page-prev a::before{
        position: absolute;
        content: '';
        background-image: url('../../../Assests/Images/Common/page-pre.svg');
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        left: -18px;
        top: 3px;
        opacity: 0.5;
    } */
/* ul.pagination .page-next{
        position: relative;
    } */
/* ul.pagination .page-next a::before{
        position: absolute;
        content: '';
        background-image: url('../../../Assests/Images/Common/page-next.svg');
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        right: -18px;
        top: 3px;
    } */
@media screen and (max-width: 576px) {
  ul.pagination li a {
    font-size: 12px;
    padding: 0 10px;
  }
  li.show-prev.show img,
  li.show-next.show img {
    width: 12px;
}
}

@import '../../../Assests/Css/Themes/_default.css';

.section-heading {
    color: var(--black);
    font-size: 44px;
    font-family: "SemiBold";
  }
  .section-sub-heading {
    color: var(--teal);
    font-family: Medium;
    font-weight: 800;
    font-size: 18px;
}
  
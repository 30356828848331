.brand-item {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.brands-page .brand-item {
  width: 184px;
}
.brand-item img {
  width: 100px;
}
.all-brands {
  column-gap: 38px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 50px;
}
.all-brands-main .css-cfq1sy-container {
  margin-top: 0;
}
.all-brands-main .css-1iljrc3-control {
  min-height: 50px;
}
.all-brands-main .css-dfrh5h-control,
.industry-landing-page .css-dfrh5h-control {
  min-height: 50px;
}
.brands-filters {
  padding-top: 1rem;
  padding-bottom: 5rem;
}
.brands-filters input {
  border-radius: 50px;
  border: 1px solid #0f8a8c;
  padding: 15px 20px;
  font-size: 14px;
}
.all-brands .brand-item:nth-child(n+7){
  margin-top: 45px;
}
@media screen and (max-width: 1399px) {
  .all-brands {
    column-gap: 49px;
  }
  .all-brands .brand-item:nth-child(n+6){
    margin-top: 45px;
  }
}
@media screen and (max-width: 1199px) {
  .all-brands {
    column-gap: 66px;
  }
  .all-brands .brand-item:nth-child(n+5){
    margin-top: 45px;
  }
}
@media screen and (max-width: 991px) {
  .all-brands {
    column-gap: 34px;
  }
  .all-brands .brand-item:nth-child(n+4){
    margin-top: 35px;
  }
  .brands-page .brand-item {
    width: 30%;
  }
}
@media screen and (max-width: 840px) {
  .all-brands {
    /* column-gap: 18px; */
  }
  .brands-filters {
    padding-top: 0rem;
    padding-bottom: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .brand-filter-btn {
    margin: auto;
  }
  .all-brands .brand-item:nth-child(n+4){
    margin-top: 25px;
  }
  .all-brands {
    column-gap: 25px;
  }
  .brands-page .brand-item {
    height: 130px;
  }
}
@media screen and (max-width: 680px) {
  .all-brands {
    /* column-gap: 63px; */
  }
}
@media screen and (max-width: 620px) {
  .all-brands {
    /* column-gap: 48px; */
  }
}
@media screen and (max-width: 576px) {
  .all-brands {
    column-gap: 18px;
  }
  .brand-item {
    height: 105px;
  }
  .brand-item img {
    width: 80px;
}
}
@media screen and (max-width: 539px) {
  .all-brands {
    column-gap: 18px;
  }
}
@media screen and (max-width: 425px) {
  .brands-page .brand-item {
    width: 48%;
  }
  .all-brands .brand-item:nth-child(n+3){
    margin-top: 15px;
  }
  .all-brands {
    column-gap: 0;
    justify-content: space-between;
  }
}
@media screen and (max-width: 372px) {
  .all-brands {
    column-gap: 8px;
  }
  .brand-item img {
    width: 60px;
  }
}
@media screen and (max-width: 322px) {
  .all-brands {
    column-gap: 4px;
  }
}

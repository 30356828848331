.cart-page .section-heading {
  font-size: 28px;
  line-height: 60px;
}
.cart-list {
  display: flex;
  padding: 1rem;
  border-radius: 10px;
}
.cart-list:nth-child(odd) {
  background-color: var(--teal-light);
}
.cart-img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
}
.cart-list-detail .section-heading {
  font-size: 20px;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}
.material {
  font-size: 16px;
  line-height: 28px;
  font-family: "Regular";
}
[dir="rtl"] .material {
  font-family: "Gulzar";
}
.material span {
  font-family: "SemiBold";
  line-height: 28px;
}
.cart-list-detail h5 {
  font-size: 28px;
  line-height: 60px;
}
.cart-details img {
  cursor: pointer;
}
.cart-page .main-form {
  padding: 30px;
}
.cart-page .cart-list-detail .qtyField input {
  padding: 0 30px;
}
.cart-list {
  transition: transform 0.6s linear;
}
.cart-list.removing {
  transform: translateX(-80%);
  opacity: 0.1;
}
.card-body h5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}
.css-1iljrc3-control {
  min-height: 50px !important;
  height: 50px;
}
.css-dfrh5h-control {
  min-height: 50px !important;
}
.cart-page .main-form p {
  font-size: 14px;
  line-height: normal;
}
.cart-page .card-is-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 70px;
  padding-bottom: 40px;
}
.cart-page .card-is-empty p {
  padding-top: 32px;
  color: var(--black);
  font-size: 24px;
  line-height: 60px;
}
.cart-page .card-is-empty .go-to-marketplace {
  padding: 18px 150px;
  margin-top: 30px;
  margin-bottom: 40px;
}
.decrease-qty img,
.increase-qty img {
  cursor: pointer;
}
.cart-page .get-quote span {
  margin-right: 100px;
}
[dir="rtl"] .cart-page .get-quote span {
  margin-right: 0px;
  margin-left: 0px;
}
.cart-list-detail .qty-meter {
  font-family: "SemiBold";
}
.cart-page .cart-list-detail .form-item {
  margin-bottom: 0;
}
[dir="rtl"] .card-body h5 {
  line-height: 52px;
  margin-top: 0 !important;
}
@media screen and (max-width: 1199px) {
  .cart-page .get-quote {
    font-size: 22px;
  }
  .cart-page .get-quote span {
    margin-right: 0px;
  }
}
@media screen and (max-width: 991px) {
  .cart-page .cart-list-detail .qtyField input {
    padding: 0 22px;
  }
}
@media screen and (max-width: 767px) {
  .cart-list-detail .section-heading {
    font-size: 18px;
  }
  .cart-list-detail .qty-meter {
    font-size: 14px;
  }
  .cart-list-detail .cart-bin {
    position: absolute;
    top: 10px;
    right: 14px;
  }
  [dir="rtl"] .cart-list-detail .cart-bin {
    left: 14px;
    right: inherit;
  }
  .material {
    font-size: 14px;
    line-height: 24px;
  }
  .cart-page .attribute-names {
    padding: 0;
  }
  .cart-page .get-quote span {
    margin-right: 200px;
  }
}
@media screen and (max-width: 575px) {
  .cart-img {
    width: 100px;
    height: 100px;
  }
  .cart-page .get-quote span {
    margin-right: 100px;
  }
  .cart-page .card-is-empty .go-to-marketplace {
    padding: 18px 80px;
  }
}
@media screen and (max-width: 425px) {
  .cart-img {
    width: 90px;
    height: 75px;
  }
  .cart-list-detail .section-heading {
    font-size: 16px;
  }
  .cart-page .card-is-empty p {
    font-size: 18px;
    text-align: center;
  }
  .cart-page .card-is-empty .go-to-marketplace {
    padding: 18px 40px;
    margin-top: 10px;
  }
  .material {
    font-size: 12px;
  }
  .cart-list-detail .cart-bin img {
    width: 20px;
  }
  .cart-page .decrease-qty img,
  .cart-page .increase-qty img {
    width: 30px;
  }
  .cart-page .cart-list-detail .qtyField input {
    padding: 0 24px;
  }
  .cart-page .get-quote span {
    margin-right: 70px;
  }
  .cart-list {
    padding: 12px;
  }
}
@media screen and (max-width: 375px) {
  .cart-img {
    width: 60px;
    height: 60px;
  }
  .cart-list:first-child .cart-img {
    width: 64px;
  }
  .cart-page .form-item input {
    font-size: 14px;
  }
  .cart-page .get-quote span {
    margin-right: 40px;
  }
}
@media screen and (max-width: 350px) {
  .cart-page .decrease-qty img,
  .cart-page .increase-qty img {
    width: 24px;
  }
  .cart-page .get-quote span {
    margin-right: 30px;
  }
  /* .cart-page .card-is-empty .go-to-marketplace {
    padding: 18px 40px;
  } */
}
@media screen and (max-width: 330px) {
  .cart-img {
    width: 50px;
    height: 50px;
  }
  .cart-list:first-child .cart-img {
    width: 57px;
  }
  .cart-page .get-quote span {
    margin-right: 20px;
  }
}

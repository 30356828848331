.accordion-toggle {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  justify-content: space-between;
  background-color: var(--white);
  transition: 0.3s;
  padding: 24px 40px;
}
.accordion-toggle.active {
  background-color: var(--white);
  padding-bottom: 10px !important;
}
.accordion-card {
  border-radius: 40px;
  border: 1px solid #0f8a8c;
  margin-bottom: 20px;
  overflow: hidden;
}
.accordion-card:last-child {
  margin-bottom: 0;
}
.accordion-title {
  position: relative;
  margin-bottom: 0;
  color: var(--black);
  transition: 0.3s;
  padding-left: 20px;
  padding-right: 30px;
  font-family: "SemiBold";
  font-size: 18px;
}
.accordion-toggle.active .accordion-title {
  color: var(--teal);
}
.accordion-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding-top: 8px;
  padding-bottom: 24px;
  padding-left: 62px;
  padding-right: 70px;
}
.accordion-body p {
  margin-bottom: 0;
  line-height: 28px;
}
.collapsed {
  position: relative;
  overflow: hidden;
  transition: height 0.35s ease;
}
.collapsed.show {
  height: auto;
}
.accordion-toggle span {
  font-size: 50px;
  position: absolute;
  top: -17px;
}
.accordion-toggle.active span {
  color: var(--teal);
}
.accordion-icon {
  position: absolute !important;
  right: 10px;
}
.accordion-icon.open::before {
  content: "";
  background: url(../../../Assests/Images/Common/polygon.jpg);
  position: absolute;
  top: 46px;
  width: 34px;
  right: 16px;
  height: 10px;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
}
.accordion-icon.close::before {
  content: "";
  background: url(../../../Assests/Images/Common/polygon.jpg);
  transform: rotate(180deg);
  width: 40px;
  height: 10px;
  position: absolute;
  right: 20px;
  top: 46px;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
}
.faqs-container {
  margin-bottom: 300px;
}

@media screen and (max-width: 991px) {
  .faqs-container {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 767px) {
  .accordion-title {
    font-size: 16px;
  }
}
@media screen and (max-width: 575px) {
  .accordion-toggle {
    padding: 22px 30px;
  }
  .accordion-body {
    padding-left: 50px;
    padding-right: 60px;
  }
  .accordion-toggle span {
    top: -22px;
  }
}
@media screen and (max-width: 475px) {
  .accordion-toggle span {
    top: -20px;
  }
}
@media screen and (max-width: 420px) {
  .accordion-body {
    padding-right: 50px;
  }
  .accordion-toggle {
    padding: 18px 30px;
  }
  .accordion-toggle span {
    top: -22px;
  }
}
@media screen and (max-width: 375px) {
  .accordion-title {
    font-size: 14px;
  }
}

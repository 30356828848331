.hero-col-1 {
  width: 22%;
}
.hero-col-2 {
  width: 50.2%;
}
.hero-col-3 {
  width: 23%;
}
.mp-hero-container {
  margin-top: 30px;
}
.mp-hero-container .get-quote {
  font-size: 18px;
}
.mp-hero-container .main-form p {
  font-size: 12px;
  line-height: normal;
}
.main-form .primary-btn {
  padding: 14px 25px;
  font-size: 14px;
}
[dir ="rtl"] .main-form .primary-btn {
  font-size: 18px;
}
.mp-hero-container .main-form input {
  padding: 7px 20px;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Regular";
}
/* .main-form input.css-mohuvp-dummyInput-DummyInput {
  padding: 0;
} */
.mp-hero-container .main-form input::placeholder {
  color: rgba(0, 0, 0, 0.8);
  /* color: rgba(0, 0, 0, 0.5); */
  font-size: 12px;
  font-family: "Regular";
}
.css-qbdosj-Input input {
  grid-area: auto !important;
  min-width: 0 !important;
}
.top-categories-colum {
  padding: 20px;
  border: 1px solid var(--teal);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.top-categories-colum h2 {
  font-family: "SemiBold";
  font-size: 18px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
.categories-list .ct-list {
  margin-top: 13px;
  padding-left: 12px;
}
.top-categories-colum .categories-list {
  max-height: 317px;
  overflow-y: auto;
  direction: rtl;
}
[dir="rtl"] .categories-list .ct-list {
  /* justify-content: flex-end; */
  padding-left: 0;
  padding-right: 10px;
  direction: rtl;
}
[dir="rtl"] .top-categories-colum .categories-list {
  direction: ltr;
}
/* .industry-landing-page .hero-industies-slider-row {
  height: 450px;
} */
.explore .expolre-categories {
  line-height: 30px;
}
[dir="rtl"] .main-form .primary-btn {
  padding: 7px 25px 14px;
}
@media screen and (max-width: 1400px) {
  .main-form .primary-btn {
    font-size: 13px;
  }
  [dir="rtl"] .top-categories-colum .categories-list {
    max-height: 310px;
  }
  .top-categories-colum {
    /* height: 440px; */
  }
  [dir="rtl"] .mp-hero-container .main-form p {
    font-size: 10px;
  }
}

@media screen and (max-width: 1199px) {
  .top-categories-colum {
    display: none;
  }
  .hero-col-2 {
    width: 64%;
  }
  .hero-col-3 {
    width: 34%;
  }
  .his-img img {
    height: 444px;
  }
}

@media screen and (max-width: 991px) {
  .main-form .primary-btn {
    padding: 12px 18px;
    font-size: 12px;
  }
  .mp-hero-container .main-form p {
    font-size: 11px;
  }
  [dir = "rtl"]  .mp-hero-container .main-form p {
  line-height: 16px;
  }
  .mp-hero-container .get-quote {
    font-size: 16px;
  }
  .hero-col-2 .his-img img {
    height: 100%;
  }
  .his-content h1 {
    font-family: "SemiBold";
    font-size: 24px;
    line-height: 30px;
  }
  .hero-col-2 {
    width: 65%;
  }
  .hero-col-3 {
    width: 34%;
  }
}
@media screen and (max-width: 767px) {
  .mp-hero-container .main-form {
    padding: 40px;
  }
  .hero-col-2,
  .hero-col-3 {
    width: 100%;
  }
  .mp-hero-container .get-quote {
    font-size: 20px;
  }
  .his-content {
    padding: 0 30px 0 30px;
  }
  .hero-industies-slider-row .slick-next {
    bottom: 20px;
  }
  .his-content h1 {
    font-size: 18px;
    line-height: 24px;
  }
  .his-content p {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .hero-col-2 .his-content {
    /* bottom: 160px; */
  }
  /* .his-content h1 {
    font-size: 22px;
    line-height: 28px;
  } */
[dir="rtl"] .hero-industies-slider-row .slick-dots{
  bottom: 24px;
}
  .hero-industies-slider-row .slick-dots {
    /* bottom: 118px; */
  }
  .hero-industies-slider-row .slick-next {
    /* bottom: 100px; */
  }
}
@media screen and (max-width: 475px) {
  .hero-col-2 .his-content {
    /* bottom: 190px; */
  }

  .hero-industies-slider-row .slick-dots {
    /* bottom: 160px; */
  }
  .hero-industies-slider-row .slick-next {
    /* bottom: 145px; */
  }
}
@media screen and (max-width: 425px) {
  .mp-hero-container .main-form {
    padding: 30px;
  }
  .hero-col-2 .his-content {
    /* bottom: 220px; */
  }
  .hero-industies-slider-row .slick-dots {
    /* bottom: 195px; */
  }
  .hero-industies-slider-row .slick-next {
    /* bottom: 175px; */
  }
}
@media screen and (max-width: 375px) {
  .his-content h1 {
    font-size: 18px;
    line-height: 24px;
  }
}
@media screen and (max-width: 350px) {
  .mp-hero-container .main-form {
    padding: 20px;
  }
  .mp-hero-container .get-quote {
    font-size: 18px;
  }
}

.footer-container {
  margin: 50px 0px 0 0;
  padding: 100px 0px 80px;
  /* border-radius: 100px 100px 0px 0px; */
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.footer-links h5 {
  line-height: 60px;
  font-family: "SemiBold";
}
.footer-links li a {
  font-size: 16px;
  line-height: 40px;
  color: var(--gray-light);
  font-family: "Regular";
}
.footer-container .footer-copyright {
  margin-top: 80px;
}
.footer-newsletter .section-heading {
  font-size: 28px;
}
/* @media screen and (max-width: 1400px) { */
.footer-copyright .footer-bottom-images a img {
  width: 162px;
}
.footer-copyright .footer-bottom-images a:nth-child(2) img {
  margin-left: 15px;
}
[dir="rtl"] .footer-copyright .footer-bottom-images a:nth-child(2) img {
  margin-left: 0;
  margin-right: 15px;
}
/* } */
@media screen and (max-width: 1399px) {
  .footer-links h5 {
    font-size: 18px;
  }
  .footer-links li a{
    text-wrap: nowrap;
  }
}
@media screen and (max-width: 1199px) {
  .left-side {
    order: 1;
    text-align: center;
  }
  .right-side {
    order: 2;
    text-align: center;
    padding-top: 40px;
  }
  .footer-newsletter {
    padding-bottom: 0;
  }
  .footer-newsletter .newsletter .section-heading {
    font-size: 22px;
  }
}
@media screen and (max-width: 991px) {
  .footer-copyright .footer-bottom-images a img {
    width: 160px;
  }
  .footer-container {
    padding: 30px 0px 80px;
  }
}
@media screen and (max-width: 767px) {
  .footer-copyright {
    display: flex;
    flex-direction: column;
  }
  .footer-container {
    margin: 0;
  }
  .footer-container .left-side .zaraye-footer-logo {
    width: 200px;
  }
  .footer-copyright p {
    order: 2;
    padding-top: 40px;
    text-align: center;
  }
  .footer-container .footer-copyright {
    margin-top: 40px;
  }
}
@media screen and (max-width: 575px) {
  .right-side {
    order: 1;
    text-align: start;
  }
  .right-side .footer-links {
    padding-bottom: 30px;
  }
  .left-side {
    order: 2;
    text-align: center;
  }
  .footer-links h5 {
    line-height: 30px;
    font-size: 22px;
  }
  .footer-copyright p {
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 420px) {
  .footer-copyright .footer-bottom-images a img {
    width: 120px;
  }
}

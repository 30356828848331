.landing-header-title h1 {
  color: var(--black);
  text-align: center;
  font-size: 28px;
  font-family: "SemiBold";
  line-height: 36px;
}
.header-back-drop-right {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.header-back-drop-left {
  position: absolute;
  left: 0;
  top: 0;
}
@media screen and (max-width: 840px) {
  .sub-header-container {
    margin: 70px 0;
  }
}
@media screen and (max-width: 680px) {
  .landing-header-title h1 {
    font-size: 22px;
    line-height: 32px;
  }
  .sub-header-container {
    margin: 50px 0;
  }
}
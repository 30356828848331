.form-6-colum .form-group {
  width: 48%;
}
.error-msg {
  font-size: 11px;
  color: red;
  font-family: "Regular";
}
.custom-number-input {
  margin-top: 13px;
}
.custom-number-input .iti__selected-dial-code {
  font-size: 13px;
  color: var(--gray-light);
  padding-left: 4px;
  font-family: "Regular";
}
.custom-number-input .iti--separate-dial-code .iti__selected-flag {
  border-radius: 50px 0 0 50px;
}
.custom-number-input .invalid-phone {
  border-color: red !important;
}
[dir="rtl"] .custom-number-input .iti--separate-dial-code .iti__selected-flag {
  border-radius: 0 50px 50px 0;
}
[dir="rtl"] .iti--allow-dropdown input {
  text-align: right;
  padding-right: 90px !important;
}
.mp-hero-container .main-form .units input,
.mp-hero-container .main-form .countries input,
.main-form .units input,
.main-form .countries input {
  padding: 0 20px;
}

.prod-img {
  border-radius: 10px;
}

/* .prod-img-fix {
  width: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  height: 129px;
  object-fit: cover;
} */
.pd-cat-name {
  color: var(--black);
}

.prod-fields {
  max-height: 470px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 5px;
  /* padding-bottom: 45px; */
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  background:
    linear-gradient(white 30%,
      rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    radial-gradient(farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)) center top,
    radial-gradient(farthest-side at 50% 100%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)) center bottom;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

/* Scroll bar stylings */
.prod-fields::-webkit-scrollbar {
  width: 10px;
}

.prod-img {
  position: relative;
  display: block;
  overflow: hidden;
  background-size: 100% !important;
  object-fit: cover;
  background-position: center center !important;
}

.select-prod-box-height {
  height: 560px;
}

.prod-img::before {
  content: "";
  left: 0;
  right: 0;
  top: 39%;
  border-radius: 6px;
  bottom: 0;
  z-index: 99;
  position: absolute;
}

.pd-prod-title {
  color: var(--black);
  font-size: 36px;
  font-family: "SemiBold";
}

.pd-ask-question::before {
  content: "|";
  padding-right: 12px;
  color: var(--gray-light);
}

[dir="rtl"] .pd-ask-question::before {
  padding-right: 0;
  padding-left: 12px;
}

[dir="rtl"] .pd-ask-question::after {
  padding-left: 0;
  padding-right: 12px;
}

.pd-ask-question::after {
  content: "|";
  padding-left: 12px;
  color: var(--gray-light);
}

.in-stock p {
  color: #24c40b;
}

.in-stock img {
  width: 18px;
}

.pd-reviews-row p {
  font-size: 14px;
}

.form-item {
  position: relative;
  margin-bottom: 15px;
}

.form-item p {
  color: var(--black);
}

.form-item input {
  display: block;
  width: 100%;
  height: 50px;
  background: transparent;
  border: solid 1px #ccc;
  transition: all 0.3s ease;
  padding: 0 15px;
  border-radius: 50px;
  font-family: "Regular";
}

.form-item input {
  border-color: var(--teal);
}

.form-item input:focus-visible {
  outline: 0;
}

.form-item label {
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 13px;
  left: 25px;
  font-size: 12px;
  background: var(--white);
  padding: 0 5px;
  color: #999;
  transition: all 0.3s ease;
}

[dir="rtl"] .form-item label {
  left: inherit;
  right: 25px;
}

.form-item input+label {
  font-size: 11px;
  top: -12px;
  font-family: "Regular";
}

.form-item input+label {
  color: var(--black);
}

.form {
  width: 500px;
  margin: 0 auto;
  margin-top: 150px;
  font-family: sans-serif;
  background: var(--white);
}

.qtyField input {
  padding: 0 52px;
  text-align: center;
}

.decrease-qty,
.increase-qty {
  position: absolute;
  border: 0;
  background-color: transparent;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.decrease-qty {
  left: 5px;
}

.increase-qty {
  right: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.product-description-section,
.also-like-section,
.explore-product-section.get-zaraye-app-section {
  margin-top: 100px;
}

.prod-detail-right {
  padding-left: 3rem;
}

[dir="rtl"] .prod-detail-right {
  padding-left: 0;
  padding-right: 3rem;
}

.product-description-section .tab-content p pre {
  white-space: normal;
}

.recent-view-products {
  padding: 130px 0 50px;
}

@media screen and (max-width: 991px) {
  .prod-detail-right {
    padding-left: 0;
  }
}

@media screen and (max-width: 576px) {
  .prod-detail-pg .heading-bb {
    border: 0;
  }
  .product-description-section{
    margin-top: 80px;
  }
  .prod-detail-pg .resp-view-all {
    bottom: 0;
  }

  .recent-view-products {
    padding-top: 40px;
  }

  .recent-view-products .resp-view-all {
    bottom: -50px;
  }

  .prod-detail-pg .get-zaraye-app-section {
    margin-top: 120px;
  }

  .prod-fields {
    padding-right: 15px;
  }

  [dir="rtl"] .prod-fields {
    padding-right: 0;
    padding-left: 15px;
  }

  .prod-detail-right {
    padding: 0 20px;
  }

  .pd-category .pd-star,
  .pd-category .pd-reviews {
    display: none;
  }

  .pd-ask-question::before {
    display: none;
  }

  .select-prod-box-height {
    height: 450px;
  }

  .also-like-section {
    margin-top: 30px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 475px) {
  .select-prod-box-height {
    height: 370px;
  }

  .prod-detail-btn {
    flex-direction: column;
  }

  .prod-detail-btn p {
    padding-top: 20px;
  }

  .prod-detail-btn button {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 430px) {
  .also-like-section .column-gap .slick-slide img {
    height: auto;
  }
}

@media screen and (max-width: 420px) {
  .select-prod-box-height {
    height: 320px;
  }

  .prod-detail-right .pd-prod-title {
    font-size: 22px;
  }
}

@media screen and (max-width: 375px) {
  .select-prod-box-height {
    height: 290px;
  }

  .qtyField input {
    padding: 0px 28px;
    height: 44px;
  }

  .decrease-qty img,
  .increase-qty img {
    width: 36px;
  }
}

@media screen and (max-width: 330px) {
  .select-prod-box-height {
    height: 270px;
  }

  .qtyField input {
    padding: 0px 24px;
  }
}

.mp-hero-container .main-form .css-mohuvp-dummyInput-DummyInput {
  padding: 0;
}
@import "../../../Assests/Css/Abstract/_variables.css";

.top-header {
    border-right: 1px solid var(--black);
    padding: 0px 1.2rem;
    font-size: 14px;
    margin-top: 1.2rem;
}
.currency {
    color: var(--gray-light);
}
.red {
    color: var(--danger);
}
.green {
    color: var(--success);
}
.zaraye-global-pg .main-header {
    margin: 20px 0px 0px 0px;
    border: 2px solid var(--teal);
    padding: 15px 30px;
    border-radius: 40px;
    left: 0;
    right: 0;
    position: absolute;
    background: rgba(255, 255, 255, 0.75);
    z-index: 10;
}
.websites-links {
    padding: 0px 24px;
    z-index: 10;
}
.websites-links ul li {
    padding: 0px 15px;
}
.websites-links ul li a {
    color: var(--black) !important;
}
/* .header-btn {
    padding: 11px 30px;
} */
.zaraye-global-pg .get-customize {
    display: none;
}
@media screen and (max-width: 575px) {
    .zaraye-global-pg .main-header .zaraye-global-img {
        width: 160px;
    }
}
@media screen and (max-width: 375px) {
    .zaraye-global-pg .main-header .zaraye-global-img {
        width: 140px;
    }
}
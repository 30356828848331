.vid-container{
    position: relative;
}
.video-column{
    margin-top: -350px;
}
.vid-container video{
    border-radius: 25px;
    object-fit: cover;
}
.vid-control {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    transition: opacity 0.2s linear;
    opacity: 0;
}
.vid-control.hovered {
    opacity: 1;
}
.play-pause-button{
    background-color: transparent;
    border: 0;
}
.playBtn{
    background-image: url('../../../Assests/Images/Global/vid-paly.png');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: 50%;
}
.pauseBtn{
    background-image: url('../../../Assests/Images/Global/vid-pause.png');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: 50%;
}
.vid-control button:active {
    box-shadow: none;
}
@media screen and (max-width: 500px) {
    .playBtn{
        background-image: url('../../../Assests/Images/Global/vid-play-resp.png');
    }
    .pauseBtn{
        background-image: url('../../../Assests/Images/Global/vid-pause-resp.png');
    }
}

.client-parent {
  border-radius: 20px;
  border: 2px solid var(--teal);
  padding: 37px 40px 32px 40px;
  height: 310px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}
.client-says-title h4 {
  color: var(--black);
  font-family: "SemiBold";
  font-size: 18px;
  line-height: 60px;
  margin-bottom: 0;
}
.client-detail h2 {
  color: var(--black);
  font-family: "SemiBold";
  font-size: 24px;
  margin-bottom: 0;
}
.client-detail span {
  color: var(--gray-light);
  font-family: "Italic";
  font-size: 16px;
}
.our-customer-section {
  position: relative;
  padding: 100px 0 50px;
}
.client-slider {
  padding-top: 1.9rem;
}
.customers-slider {
  z-index: 10;
}
.slider-overlay {
  /* position: relative; */
  /* margin-left: -50px;
  z-index: 5; */
}
.slider-overlay:after {
  content: "";
  height: 220px;
  z-index: 9999;
  position: absolute;
  box-shadow: 0 0 40px 60px var(--white);
  width: 0;
  bottom: 64px;
  left: -30px;
}
.slider-end-overlay {
  /* position: relative; */
  /* margin-left: -50px;
  z-index: 5; */
}
.slider-end-overlay:after {
  content: "";
  height: 220px;
  z-index: 9999;
  position: absolute;
  right: 0;
  box-shadow: 0 0 40px 60px var(--white);
  width: 0;
  bottom: 64px;
  right: -30px;
}
.client-img img {
  width: 70px;
}
@media screen and (max-width: 1199px) {
  .client-slider .client-parent {
    height: 370px;
  }
  .slider-overlay:after,
  .slider-end-overlay:after {
    height: 280px;
  }
}
@media screen and (max-width: 991px) {
  .client-slider .client-parent {
    padding: 24px;
    text-align: center;
    height: 470px;
  }
  .client-slider .client-parent .client-detail {
    padding-top: 22px;
  }
  .slider-overlay:after,
  .slider-end-overlay:after {
    height: 380px;
  }
}
@media screen and (max-width: 767px) {
  .client-slider .client-parent {
    padding: 30px;
    text-align: center;
    height: 340px;
  }
  .client-slider {
    padding-top: 1rem;
  }
  .slider-overlay:after,
  .slider-end-overlay:after {
    box-shadow: 0 0 40px 60px #ffffff8a;
  }
}
@media screen and (max-width: 575px) {
  .client-parent .client-message p {
    font-size: 16px;
  }
  .client-slider .client-parent {
    height: 400px;
  }
  .our-customer-section .section-description {
    text-align: center;
  }
  .our-customer-section .selling-products-slider .slick-list {
    margin: 0 !important;
  }
}

@media screen and (max-width: 475px) {
  .client-slider .client-parent {
    height: 440px;
  }
}
@media screen and (max-width: 360px) {
  .client-slider .client-parent {
    padding: 26px;
    height: 460px;
  }
}
@media screen and (max-width: 340px) {
  .client-slider .client-parent {
    padding: 22px;
    height: 460px;
  }
  .client-parent .client-message p {
    font-size: 15px;
  }
}

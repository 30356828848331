.dropdown {
  position: relative;
  display: flex;
    justify-content: flex-end;
}

.dropdown-toggle {
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
.langu-help .f-regular{
  font-family: 'Regular' !important;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: #f1f1f1;
  list-style-type: none;
  padding: 0;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #ddd;
}
.custom-dropdown-icon {
  background-color: transparent;
  color: var(--black);
  display: flex;
  align-items: center;
  font-family: 'Regular';
}
[dir="rtl"] .custom-dropdown .dropdown-item {
  text-align: right;
  font-family: 'Regular';
}
.custom-dropdown-icon:is(:hover, :active) {
  background-color: transparent;
  color: var(--black);
}
.custom-dropdown .dropdown-item {
  color: var(--black);
  padding: 4px 20px;
  cursor: pointer;
}
.custom-dropdown .dropdown-item a {
  color: var(--black);
  text-decoration: none;
  font-family: "Regular";
}
.custom-dropdown .dropdown-item:is(:hover, :focus){
  color: var(--bs-dropdown-link-hover-color);
  background-color: #ffffff5c;
}
.custom-dropdown .dropdown-menu.show {
  display: block;
  padding: 8px 0;
  border-color: var(--teal);
  background: rgba(255, 255, 255, 0.8);
  stroke-width: 1px;
  stroke: #0f8a8c;
  backdrop-filter: blur(10px);

  transform: translate(-20px, 46px) !important;
}
.header-nav .custom-dropdown .dropdown-menu.show {
  margin-top: 13px;
}
.custom-dropdown-icon::after {
  content: "";
  display: inline-block;
  width: 13px;
  height: 10px;
  background-image: url("../../../Assests/Images/Common/menu-arrow-down.svg");
  margin-left: 5px;
  border: 0;
  background-repeat: no-repeat;
}
.dropdown-menu::after {
  position: absolute;
  top: -0.78rem;
  left: 0;
  right: 0;
  width: 41px;
  height: 12px;
  background-image: url("../../../Assests/Images/Common/polygon.svg");
  background-repeat: no-repeat;
  content: "";
  display: block;
  z-index: 2;
  transition: all 200ms linear;
  margin: auto;
}
[dir="rtl"] .dropdown-menu::after {
  top: -0.72rem;
}
.collapse {
  margin: 10px;
}
.cursor-pointer{
  cursor: pointer;
}
.collapse:not(.show) {
  display: block;
  margin: 0;
}
.collapse.collapsed .collapse-button::after {
  content: "";
  transform: rotate(90deg);
  transition: 0.3s;
}
.collapse .collapse-button::after {
  content: "";
  position: absolute;
  background-image: url(http://localhost:3000/static/media/menu-arrow-down.c320841cc8222f9015fe.svg);
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  right: 0;
  top: 11px;
  transition: 0.3s;
}
.collapse-button {
  position: relative;
  background-color: transparent;
  color: var(--black);
  border: none;
  padding: 0 0 8px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-family: "SemiBold";
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.filter-sidebar .collapse-content {
  max-height: 396px;
  overflow-y: auto;
  transition: max-height 0.3s ease;
}

.collapsed .collapse-content {
  max-height: 0;
}

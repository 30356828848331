.brands-box {
  width: 31.9%;
  border-radius: 10px;
  border: 2px solid var(--teal);
  background: var(--white);
  padding: 40px 30px;
}
.brands-box h2 {
  font-size: 20px !important;
}
[dir="rtl"] .cart-item-resp .brands-box h2{
  margin-bottom: 15px;
}
.brands-box p {
  color: var(--black) !important;
}
.brands-box h2 {
  font-size: 20px;
  color: var(--black) !important;
}
.brands-box p {
  font-size: 14px;
  font-family: "Light";
  line-height: 20px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.cores .section-heading {
  font-size: 44px !important;
}
[dir="rtl"] .cores .section-heading{
  letter-spacing: 0.07rem;
  padding-top: 1.25rem;
}
.manu-brands .brand-slider .brand-item {
  justify-content: center !important;
}
.zaraye-core-values {
  margin: 100px 0 140px;
  padding: 160px 0 170px;
  background-color: var(--teal);
}
.about-brand-slider-section {
  margin-top: -234px;
}
.cores .section-heading,
.cores p {
  color: var(--white);
}
[dir="rtl"] .brands-box .cart-item-resp {
  text-align: right !important;
}
@media screen and (max-width: 1199px) {
  .manu-brands-res .brands-box {
    width: 32.5%;
    padding: 30px 20px;
  }
  .manu-brands-res .brands-box img {
    padding-right: 20px;
    width: 80px;
  }
  [dir="rtl"] .manu-brands-res .brands-box img {
    padding-left: 20px;
    padding-right: 0;
  }
  .manu-brands-res .about-brand-slider-section .section-heading {
    font-size: 16px !important;
  }
  .manu-brands-res .brands-box p {
    font-size: 13px;
    line-height: 18px;
  }
  .about-brand-slider-section {
    margin-top: -200px;
  }
  .brand-slider {
    bottom: -66px;
  }
  .cores .section-heading {
    font-size: 36px !important;
  }
  .zaraye-core-values {
    padding: 140px 0 150px;
    margin: 80px 0 140px;
  }
}
@media screen and (max-width: 991px) {
  .brands-box .brands-box-item {
    flex-direction: column;
    justify-content: space-between;
  }
  .manu-brands-res .brands-box {
    padding: 20px 10px;
  }
  .manu-brands-res .about-brand-slider-section .section-heading,
  .manu-brands-res .brands-box p {
    text-align: center;
  }
  .manu-brands-res .brands-box img {
    padding-bottom: 20px;
    padding-right: 0px;
    margin: 0 auto;
    width: auto;
  }
  [dir="rtl"] .manu-brands-res .brands-box img {
    padding-left: 0;
  }
  .manu-brands-res .brands-box:last-child img {
    padding-top: 16px;
  }
  .manu-brands-res .brands-box {
    display: flex;
  }
  .zaraye-core-values {
    padding: 160px 0 150px;
  }
  .our-colab-network .zaraye-core-values,
  .valued-investor-section .zaraye-core-values {
    padding: 130px 0 150px;
  }
  .about-brand-slider-section {
    margin-top: -260px;
  }
  .our-colab-network .about-brand-slider-section,
  .valued-investor-section .about-brand-slider-section {
    margin-top: -200px;
  }
  .core-values-slider .brand-slider {
    bottom: -260px;
  }
}
@media screen and (max-width: 840px) {
  .cores .section-heading {
    font-size: 32px !important;
  }
}
@media screen and (max-width: 767px) {
  .manu-brands-res .brands-box {
    width: 100%;
    margin-bottom: 12px;
  }

  .brand-item {
    border: 1px solid var(--teal);
  }
  .brand-slider .slick-prev,
  .brand-slider .slick-next {
    display: block !important;
    z-index: 10;
    margin: 0 auto;
  }
  .brand-slider .slick-next {
    /* top: 160px; */
    left: 0;
    right: -100px;
    width: 60px;
    top: inherit;
    bottom: -70px;
  }
  .brand-slider .slick-prev {
    /* top: 160px; */
    left: -100px;
    right: 0;
    width: 60px;
    top: inherit;
    bottom: -70px;
  }
  .valued-investor-section .brand-slider .slick-next,
  .valued-investor-section .brand-slider .slick-prev {
    top: 260px;
  }
  .mp-home .zaraye-core-values,
  .industry-landing-page .zaraye-core-values {
    margin: 0;
    background-color: transparent;
    padding: 0;
  }
  .our-colab-network .zaraye-core-values {
    padding: 130px 0 160px;
  }
  .valued-investor-section .zaraye-core-values {
    padding: 130px 0 130px;
  }
  .zaraye-core-values {
    padding: 0 0 120px;
  }
  .brands-box .brands-box-item {
    width: 100%;
    flex-direction: row;
    padding: 10px 20px;
  }
  .manu-brands-res .brands-box img {
    padding-bottom: 0;
  }
  .manu-brands-res .about-brand-slider-section .section-heading,
  .manu-brands-res .brands-box p {
    text-align: start;
  }
  .manu-brands-res .about-brand-slider-section .section-heading {
    font-size: 20px !important;
  }
  .manu-brands-res .brands-box p {
    font-size: 14px;
  }
  .manu-brands-res .brands-box:last-child img {
    padding-top: 0px;
  }
  .zaraye-core-values .section-heading {
    color: var(--black);
  }
  .cores p {
    color: var(--gray-light);
  }
  .mp-home .zaraye-core-values .about-brand-slider-section,
  .industry-landing-page .zaraye-core-values .about-brand-slider-section {
    padding-bottom: 50px;
    margin-top: 0;
  }
  .industry-landing-page .brand-slider {
    position: static;
    margin-top: 30px;
  }
  .brand-slider .brand-item {
    /* width: 140px !important; */
    height: 110px;
  }
  .brand-slider .brand-item img {
    width: 70px;
  }
}
@media screen and (max-width: 680px) {
  .brand-slider .brand-item {
    /* width: 130px !important; */
    height: 100px;
  }
  .cores .section-heading {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 620px) {
  .brand-slider .brand-item {
    /* width: 140px !important; */
    height: 110px;
  }
}
@media screen and (max-width: 576px) {
  .brand-slider {
    /* padding: 0px 32px 0 6px; */
    left: 30px;
  }
  .valued-investor-section .brand-slider .slick-next,
  .valued-investor-section .brand-slider .slick-prev {
    top: 160px;
  }
  .our-colab-network .about-brand-slider-section,
  .valued-investor-section .about-brand-slider-section {
    margin-top: -180px;
    padding: 0;
  }
  .cores .section-heading {
    font-size: 22px !important;
  }
  .about-brand-slider-section {
    padding: 0;
  }

  .manu-brands-res .brands-box img {
    width: 60px;
  }
}
@media screen and (max-width: 540px) {
  .brand-slider .slick-next {
    /* top: 160px; */
    /* right: 160px; */
  }
  .manu-brands-res .row .brands-box:nth-child(3) {
    margin-bottom: 0;
      }
  .brand-slider .slick-prev {
    /* top: 160px; */
    /* left: 150px; */
  }
  .manu-brands-res .brands-box img {
    width: 50px;
  }
}
@media screen and (max-width: 500px) {
  .brand-slider .slick-next {
    /* top: 150px; */
    /* right: 140px; */
  }
  .brand-slider .slick-prev {
    /* top: 150px; */
    /* left: 120px; */
  }
  .brand-slider .brand-item {
    /* width: 120px !important; */
    height: 100px;
  }
  /* .brand-slider {
    padding: 0px 32px 0 20px;
  } */
}
@media screen and (max-width: 475px) {
  .brand-slider .slick-next {
    /* right: 35%; */
  }
  .brand-slider .slick-prev {
    /* left: 35%; */
  }
  .brand-slider .slick-next {
    /* right: 130px; */
  }
  .brand-slider .slick-prev {
    /* left: 116px; */
  }
  /* .brand-slider {
    padding: 0px 32px 0 13px;
  } */
}
@media screen and (max-width: 450px) {
  .brand-slider {
    padding: 0px 22px 0 7px;
  }
  /* .manu-brands-res .brands-box img {
    width: 50px;
  } */
  .brands-box .cart-item-resp {
    padding-left: 20px;
  }
  .brand-slider {
    padding: 0;
  }
}
@media screen and (max-width: 420px) {
  .brand-slider .brand-item {
    /* width: 110px !important; */
    height: 90px;
  }
  .brand-slider .slick-next {
    /* right: 30%; */
  }
  .brand-slider .slick-prev {
    /* left: 30%; */
  }
  .brand-slider .slick-next {
    /* top: 140px; */
    /* right: 110px; */
  }
  .brand-slider .slick-prev {
    /* top: 140px; */
    /* left: 96px; */
  }
  .our-colab-network .zaraye-core-values {
    padding: 130px 0 130px;
  }
  .core-values-slider .brand-slider {
    bottom: -230px !important;
  }
}
@media screen and (max-width: 400px) {
  /* .brand-slider {
    padding: 0px 16px 0 2px;
  } */
  .brands-box .brands-box-item {
    padding: 4px 0px 0 8px;
    width: auto;
  }
}
@media screen and (max-width: 390px) {
  .brand-slider .brand-item {
    /* width: 104px !important; */
    height: 85px;
  }

  .cores .section-heading {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 375px) {
  .brand-slider .slick-prev {
    /* top: 130px; */
    /* left: 88px; */
  }
  .brand-slider .slick-next {
    /* top: 130px; */
    /* right: 100px; */
  }
  .all-brands-resp .primary-btn {
    padding: 14px 38px;
  }

  .brand-slider .slick-prev {
    left: -90px;
  }
  .brand-slider .slick-next {
    right: -90px;
  }
  [dir="rtl"] .manu-brands-res .brands-box img {
    width: 40px;
  }
}
@media screen and (max-width: 350px) {
  .brand-item img {
    width: 60px;
  }
  .brand-slider .brand-item {
    /* width: 100px !important; */
    height: 80px;
  }
  .brand-slider .slick-next {
    /* top: 124px; */
    /* right: 100px; */
    width: 50px;
  }
  .brand-slider .slick-prev {
    /* top: 124px; */
    /* left: 90px; */
    width: 50px;
  }
  .all-brands-resp .primary-btn {
    padding: 14px 30px;
  }
}
@media screen and (max-width: 340px) {
  .brand-item {
    /* width: 94px !important; */
    height: 74px;
  }
  .manu-brands-res .brands-box img {
    width: 40px;
  }
  .manu-brands-res .about-brand-slider-section .section-heading {
    font-size: 18px !important;
    line-height: 24px;
  }

  .brand-slider .slick-next {
    /* top: 120px; */
    /* right: 90px; */
  }
  .brand-slider .slick-prev {
    /* top: 120px; */
    /* left: 80px; */
  }
}

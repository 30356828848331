.his-img {
  height: 100%;
}
.his-img img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.his-img::after {
  content: "";
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: linear-gradient(240deg, rgba(0, 0, 0, 0) 26.67%, #000 110.79%);
}
.his-content {
  position: absolute;
  bottom: 70px;
  padding: 0 100px 0 30px;
  z-index: 99;
  width: 100%;
}
.his-content h1 {
  font-family: "SemiBold";
  font-size: 28px;
  line-height: 36px;
}
.his-content p {
  font-size: 14px;
  line-height: 22px;
}
.his-content p pre {
  white-space: normal;
  font-size: 14px;
  overflow: hidden;
}
[dir="rtl"] .his-content p pre {
  line-height: 32px;
  font-size: 16px;
}
[dir="rtl"] .his-img::after {
  background: linear-gradient(158deg, rgba(0, 0, 0, 0) 26.67%, #000 110.79%);
}
.his-content a {
  color: var(--white);
  font-family: "SemiBold";
  font-size: 16px;
  text-decoration-line: underline;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  padding-right: 22px;
  transition: 0.3s;
}
[dir="rtl"] .his-content a {
  padding-right: 0;
  padding-left: 22px;
  direction: rtl;
}
.his-content a:hover {
  padding-right: 25px;
  transition: 0.3s;
}
[dir="rtl"] .his-content a:hover {
  padding-right: 0;
  padding-left: 25px;
}
.his-content a::after {
  position: absolute;
  content: ">>";
  width: 100%;
  font-family: "SemiBold";
  height: 23px;
  bottom: 0px;
  right: 0;
  border-bottom: 1px solid;
  text-align: right;
  vertical-align: middle;
}
[dir="rtl"] .his-content a::after {
  right: inherit;
  left: 0;
  text-align: left;
  top: 12px;
}
.hero-industies-slider-row {
  height: 450px;
  border-radius: 10px;
  overflow: hidden;
}

.hero-col-2 .slick-slider .slick-track,
.hero-col-2 .slick-list,
.hero-col-2 .slick-track {
  height: 100%;
}
.hero-col-2 .slick-initialized .slick-slide div:nth-child(1) {
  height: 100%;
}

.hero-industies-slider-row .slick-dots li.slick-active {
  width: 24px;
  height: 10px;
  background-color: var(--white);
  border-radius: 20px;
}
.hero-industies-slider-row .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 2px;
  transition: all 0.3s ease;
}
.hero-industies-slider-row .slick-dots li button {
  width: 10px;
  height: 10px;
  transition: all 0.3s ease;
}
.hero-industies-slider-row .slick-dots li button:before {
  width: 10px;
  height: 10px;
  line-height: 10px;
  content: "";
  background-color: var(--white);
  border-radius: 50%;
  transition: all 0.7s ease;
}
.hero-industies-slider-row .slick-dots {
  bottom: 34px;
  line-height: 10px;
  text-align: left;
  padding-left: 27px;
}
[dir="rtl"] .hero-industies-slider-row .slick-dots {
  text-align: right;
  padding-left: 0;
  padding-right: 27px;
}
.hero-industies-slider-row .slick-dots li.slick-active button:before {
  opacity: 1;
  color: var(--black);
  width: 24px;
  background: var(--white);
  border-radius: 20px;
}
.hero-industies-slider-row .slick-prev {
  display: none !important;
}
.hero-industies-slider-row .slick-next {
  left: auto;
  bottom: 30px;
  right: 25px;
  width: auto;
}
[dir="rtl"] .hero-industies-slider-row .slick-next {
  right: auto;
  left: 25px;
  transform: rotate(180deg);
  z-index: 1;
}
.hero-industies-slider-row .slick-prev,
.hero-industies-slider-row .slick-next {
  top: inherit;
}
[dir="rtl"] .slick-slide {
  float: inline-start;
}
[dir="rtl"] .his-content {
  padding: 0 30px 0 100px;
  text-align: right;
}
[dir="rtl"] .ct-list-img {
  margin-left: 1rem;
}
/* [dir="rtl"] .his-content h1 {
  text-align: right;
} */

/* .slick-slider-fade .slick-track {
  -webkit-transition: opacity 10s ease-in-out;
  -moz-transition: opacity 10s ease-in-out;
  -ms-transition: opacity 10s ease-in-out;
  -o-transition: opacity 10s ease-in-out;
  opacity: 1;
} */

[dir="rtl"] .hero-industies-slider-row {
  height: 444px;
}

@media screen and (max-width: 1400px) {
  /* .his-img img  {
    height: 440px;
    object-fit: cover;
  } */

  [dir="rtl"] .hero-industies-slider-row {
    height: 438px;
  }
}

@media screen and (max-width: 1368px) {
  [dir="rtl"] .industry-landing-page .hero-industies-slider-row {
    height: 438px;
  }
  .industry-landing-page .hero-industies-slider-row,
  .hero-industies-slider-row {
    height: 444px;
  }
  [dir="rtl"] .hero-industies-slider-row {
    height: 438px;
  }
  [dir="rtl"] .industry-landing-page .hero-industies-slider-row {
    height: 438px;
  }
}

@media screen and (max-width: 991px) {
  .hero-industies-slider-row {
    height: 434px;
  }
  .hero-industies-slider .his-content {
    padding: 0 40px 0 30px;
  }
}
@media screen and (max-width: 767px) {
  .hero-industies-slider-row,
  [dir="rtl"] .hero-industies-slider-row {
    height: 276px;
  }
  .hero-industies-slider-row .slick-next {
    width: 58px;
  }
}
@media screen and (max-width: 575px) {
  /* .hero-industies-slider-row {
    height: 350px;
    margin-bottom: 20px;
  } */
}
@media screen and (max-width: 475px) {
  /* .hero-industies-slider-row {
    height: 300px;
    margin-bottom: 20px;
  } */
}
@media screen and (max-width: 425px) {
  /* .hero-industies-slider-row {
    height: 270px;
    margin-bottom: 20px;
  } */
}

@font-face {
  font-family: "Italic";
  src: url("./Poppins-Italic.ttf") format("truetype");
  font-display: block;
}
@font-face {
  font-family: "Light";
  src: url("./Poppins-Light.ttf") format("truetype");
  font-display: block;
}
@font-face {
  font-family: "Regular";
  src: url("./Poppins-Regular.ttf") format("truetype");
  font-display: block;
}
@font-face {
  font-family: "Medium";
  src: url("./Poppins-Medium.ttf") format("truetype");
  font-display: block;
}
@font-face {
  font-family: "SemiBold";
  src: url("./Poppins-SemiBold.ttf") format("truetype");
  font-display: block;
}
@font-face {
  font-family: "Gulzar";
  src: url("./Gulzar-Regular.ttf") format("truetype");
  font-display: block;
}
@font-face {
  font-family: "NotoNaksh";
  src: url("./NotoNaskhArabic.ttf") format("truetype");
  font-display: block;
}
@font-face {
  font-family: "NotoNastaliq";
  src: url("./NotoNastaliq.ttf") format("truetype");
  font-display: block;
}
@font-face {
  font-family: "Nafees";
  src: url("./Nafees.ttf") format("truetype");
  font-display: block;
}
@font-face {
  font-family: "Jameel-Regular";
  src: url("./Jameel-Noori-Regular.ttf") format("truetype");
  font-display: block;
}
@font-face {
  font-family: "Jameel";
  src: url("./Jameel-Noori.ttf") format("truetype");
  font-display: block;
}

.popular-products-section {
  margin-top: 160px;
}

.popular-products-section .grid-product .products {
  width: 19%;
  border: 0;
}

.popular-products-section .grid-product .products:nth-child(n + 5) {
  margin-top: 0;
}

.popular-products-section .grid-product .products:nth-child(n + 6) {
  margin-top: 20px;
}

.popular-products-section .grid-product {
  gap: 16px;
}

.popular-products-section .grid-product {
  border-bottom: 0;
}

.mp-home .css-1iljrc3-control {
  min-height: 36px !important;
  height: 36px;
}

.mp-home .css-dfrh5h-control {
  min-height: 40px !important;
}

.all-products-btn {
  display: none;
}

.mp-home .grid-product {
  margin-bottom: 0;
}

.home-explore-categories-section {
  padding: 100px 0 50px;
}

[dir="rtl"] .popular-products-section .rtl-popular,
[dir="rtl"] .market-news-section .rtl-popular {
  text-align: left !important;
}

@media screen and (max-width: 1400px) {
  .mp-home .popular-products-section .grid-product {
    gap: 21px;
  }

  .mp-home .popular-products-section .grid-product .products {
    width: 263px;
  }
}

@media screen and (max-width: 1199px) {
  .mp-home .popular-products-section .grid-product {
    gap: 18px;
  }

  .mp-home .popular-products-section .grid-product .products {
    width: 220px;
    margin-top: 0;
  }

  .popular-products-section .grid-product .products:nth-child(n + 9) {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .popular-products-section .grid-product .products:nth-child(n + 7) {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .mp-home .popular-products-section .grid-product {
    gap: 31px;
    /* margin-bottom: 20px; */
  }
  .mp-home .explore-product-section {
    padding: 50px 0 0;
  }
  .our-customer-section {
    padding: 50px 0 50px;
  }

  .mp-home .popular-products-section .grid-product .products {
    width: 242px;
  }

  .popular-products-section .grid-product .products:nth-child(n + 5) {
    display: none;
  }

  .popular-products-section {
    margin-top: 50px;
  }

  .grid-product .all-products-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    /* margin-bottom: 40px; */
  }

  .mp-home .resp-view-all {
    position: absolute !important;
    bottom: -20px;
    width: 220px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 10px 26px;
    border-radius: 30px;
    font-size: 14px;
    background: var(--teal);
    color: var(--white);
    text-decoration: none;
    font-family: "SemiBold";
  }

  .mp-home .selling-products-slider .slick-arrow.slick-next,
  .mp-home .selling-products-slider .slick-arrow.slick-prev {
    top: inherit;
    bottom: -50px;
    margin: 0 auto;
  }

  .mp-home .client-slider .slick-arrow.slick-next,
  .mp-home .client-slider .slick-arrow.slick-prev {
    bottom: -60px;
  }

  .mp-home .selling-products-slider .slick-arrow.slick-next {
    top: inherit;
    left: 0;
    right: -100px;
  }

  .mp-home .selling-products-slider .slick-arrow.slick-prev {
    top: inherit;
    left: -100px;
    right: 0;
  }

  [dir="rtl"] .mp-home .selling-products-slider .slick-arrow.slick-next {
    top: inherit;
    left: 0;
    right: -100px;
  }

  [dir="rtl"] .mp-home .selling-products-slider .slick-arrow.slick-prev {
    top: inherit;
    left: -100px;
    right: 0;
  }

  .mp-hero-container .hero-col-3 {
    margin-top: 20px;
  }

  .home-explore-categories-section {
    padding: 50px 0;
  }
}

@media screen and (max-width: 575px) {
  .mp-home .popular-products-section .grid-product .products {
    width: 260px;
  }

  .mp-home .popular-products-section .grid-product {
    gap: 0;
    justify-content: space-between;
  }

  .mp-home .products {
    margin-top: 12px;
  }

  /* .product-card:nth-child(n+5) {
        display: none;
    } */

  /* .mp-home .popular-products-section .grid-product .products */
}

/* @media screen and (max-width: 767px) {
    .mp-home .popular-products-section .grid-product .products {
        width: 260px;
    }
} */
@media screen and (max-width: 540px) {
  .mp-home .popular-products-section .grid-product {
    justify-content: space-between;
    gap: 10px;
  }

  .mp-home .popular-products-section .grid-product .products {
    width: 243px;
  }
}

@media screen and (max-width: 520px) {
  .mp-home .popular-products-section .grid-product .products {
    width: 228px;
  }

  /* .mp-home .popular-products-section .grid-product {
        gap: 20px;
    } */
}

@media screen and (max-width: 499px) {
  .mp-home .popular-products-section .grid-product .products {
    width: 220px;
  }
}

@media screen and (max-width: 475px) {
  .mp-home .popular-products-section .grid-product .products {
    width: 211px;
  }
}

@media screen and (max-width: 450px) {
  .mp-home .popular-products-section .grid-product .products {
    width: 200px;
  }
}

@media screen and (max-width: 435px) {
  .mp-home .popular-products-section .grid-product .products {
    width: 190px;
  }

  /* .mp-home .popular-products-section .grid-product {
        gap: 0px;
    } */
}

@media screen and (max-width: 420px) {
  .mp-home .popular-products-section .grid-product .products {
    width: 182px;
  }
}

@media screen and (max-width: 398px) {
  .mp-home .popular-products-section .grid-product .products {
    width: 176px;
  }
}

@media screen and (max-width: 390px) {
  .mp-home .popular-products-section .grid-product .products {
    width: 170px;
  }

  .grid-product .all-products-btn button {
    padding: 14px 38px;
  }
}

/* @media screen and (max-width: 405px) {
    .mp-home .popular-products-section .grid-product .products {
        width: 180px;
    }
} */
/* @media screen and (max-width: 390px) {
    .mp-home .popular-products-section .grid-product .products {
        width: 170px;
    }
} */
/* @media screen and (max-width: 375px) {
    .mp-home .popular-products-section .grid-product .products {
        width: 220px;
        margin: 0 auto;
    }
} */
@media screen and (max-width: 350px) {
  .grid-product .all-products-btn button {
    padding: 14px 30px;
  }

  .mp-home .selling-products-slider .slick-arrow.slick-next {
    right: -90px;
  }

  .mp-home .selling-products-slider .slick-arrow.slick-prev {
    left: -90px;
  }
}

@media screen and (max-width: 320px) {
  .mp-home .popular-products-section .grid-product {
    gap: 6px;
  }
}
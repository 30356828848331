.mn-thubmnail img{
    border-radius: 20px;
    height: 380px;
    object-fit: cover;
}
.market-news-section{
    margin-top: 30px;
}
.mn-publish-detail p{
    font-size: 14px;
}
.mn-post-title h2{
    color: var(--black);
    font-family: 'SemiBold';
    font-size: 24px;
}
.mn-post-small-title h2, 
.mn-post-title h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    min-height: 36px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.mn-post-small-title h2{
    color: var(--black);
    font-family: 'SemiBold';
    font-size: 18px;
}
.mn-post-small-title p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    min-height: 36px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.small-news-post{
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}
.small-news-post:last-child{
    border-bottom: 0;
}
.mn-post-title p{
    padding-right: 70px;
    padding: 0;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    min-height: 36px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.latest-article{
    font-size: 32px;
    font-family: 'SemiBold';
    color: var(--Black);
}
.blogs-main {
    padding-top: 1.9rem;
}
.mn-post-small-title span {
    font-family: "SemiBold";
    color: var(--teal);
    text-decoration: underline;
}
[dir="rtl"] .mn-post-small-title span{
    text-decoration: none;
    font-family: 'Gulzar';
}
.mn-post-small-title p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;
}
@media screen and (max-width: 1199px)  {
    .mn-publish-detail p {
        font-size: 13px;
    }
}
@media screen and (max-width: 991px)  {
    .mn-thubmnail img {
        height: 320px;
    }
    .latest-article {
        padding: 12px 0px 0px 0;
    }
    .mn-publish-detail p {
        font-size: 14px;
    }
    .small-news-post {
        padding: 20px 0;
    }
    .small-news-post:last-child {
        display: none;
    }
    .mn-post-small-title h2 {
        font-size: 24px;
    }
    .mn-post-small-title p {
        -webkit-line-clamp: 2;
    }
    .market-news-section .heading-bb {
        border-bottom: 0;
    }
    .blogs-main {
        padding-top: 1rem;
    }
}
@media screen and (max-width: 767px) {
    .blogs-main {
        padding-top: 0;
    }
    .mn-publish-detail p {
        font-size: 12px;
    }
    .mn-post-title h2,
    .mn-post-small-title h2 {
        font-size: 18px;
        line-height: 24px;
    }
    .small-news-post {
        padding: 16px 0;
    }
    .blogs-main .blogs-left .small-news-post:nth-child(3){
        margin-bottom: 0;
    }
    .latest-article {
        font-size: 28px;
        line-height: 36px;
    }
    .market-news-section {
        margin-top: 0;
    }
}
@media screen and (max-width: 575px) {
    .blogs-main .blogs-left {
        padding-left: 12px;
        padding-right: 12px;
    }
    .market-news-section .heading-bb .section-description {
        text-align: center;
    }
}
@media screen and (max-width: 425px) {
    .mn-thubmnail img {
        height: 220px;
    }
}
@media screen and (max-width: 375px) {
    .mn-thubmnail img {
        height: 200px;
    }
}
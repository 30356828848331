.column-gap .slick-slide img {
  border-radius: 10px;
  height: 260px;
  object-fit: cover;
}
.industries-main {
  position: relative;
  border-bottom: 0px !important;
}
.industries-main .nav {
  position: absolute;
  /* top: 64px; */
  right: 0px;
  bottom: 30px;
}
[dir="rtl"] .industries-tabs {
  padding-right: 0rem;
  padding-left: 1rem !important;
}
[dir="rtl"] .industries-main .nav {
  right: inherit;
  left: 0;
}
.selling-products-slider .slick-slider {
  width: 100%;
}
.industries-main .nav-tabs {
  border-bottom: 0px;
}
.industries-main .nav-tabs .nav-link {
  border-color: transparent;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  padding-left: 30px;
  font-family: "Regular";
}
[dir="rtl"] .industries-main .nav-tabs .nav-link {
  padding-left: 0px;
  padding-right: 30px;
  font-size: 18px;
}
.industries-main .nav-tabs .nav-link.active-tab {
  color: var(--black) !important;
  font-family: "SemiBold";
  text-decoration: underline;
}
[dir="rtl"] .industries-main .nav-tabs .nav-link.active-tab {
  font-weight: 600;
}
.industries-main .nav-link {
  color: var(--black) !important;
}
.industries {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.industries-main .nav-item:last-child .nav-link {
  padding-right: 0px !important;
}
.industries .slick-track {
  width: max-content !important;
}

@media screen and (max-width: 1199px) {
  /* .column-gap .slick-slide img {
        height: 240px;
    }
    .selling-products-slider .slick-initialized .slick-slide {
        padding: 0px 0px 10px 10px;
    } */

  .industries-main .nav {
    bottom: 20px;
    top: inherit;
    border-bottom: 0;
  }
}
@media screen and (max-width: 991px) {
  .industries-main .industries-tabs {
    padding-bottom: 50px;
  }
  .industries-main .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .industries-main .slick-arrow.slick-prev,
  .industries-main .slick-arrow.slick-next {
    display: none !important;
  }
  .industries-main .nav-tabs .nav-link:first-child {
    padding-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .industries-main .nav-tabs .nav-link {
    padding-left: 24px;
  }
  .industries {
    border-top: 0;
  }
  .industries .all-brands-resp {
    margin-top: 55px;
  }
}
@media screen and (max-width: 575px) {
  .industries-main .slick-arrow.slick-prev,
  .industries-main .slick-arrow.slick-next {
    display: block !important;
  }
  .column-gap .slick-slide img {
    height: 200px;
  }
  .industries-main .all-brands-resp {
    bottom: -110px;
    top: inherit;
    z-index: 10;
  }
  .industries-main .slick-arrow.slick-prev {
    /* top: 410px !important; */
    /* left: 200px !important; */
    z-index: 10;
  }
  .industries-main .slick-arrow.slick-next {
    /* top: 410px !important; */
    /* right: 200px !important; */
    z-index: 10;
  }
}
@media screen and (max-width: 540px) {
  .industries-main .slick-arrow.slick-prev {
    /* left: 174px !important; */
  }
  .industries-main .slick-arrow.slick-next {
    /* right: 174px !important; */
  }
  .industries-main .nav-tabs .nav-link {
    padding-left: 24px;
  }
}
@media screen and (max-width: 500px) {
  .industries-main .slick-arrow.slick-prev {
    /* left: 160px !important; */
  }
  .industries-main .slick-arrow.slick-next {
    /* right: 160px !important; */
  }
  .industries-main .nav-tabs .nav-link {
    padding-left: 22px;
    font-size: 14px;
  }
}
@media screen and (max-width: 475px) {
  .industries-main .slick-arrow.slick-prev {
    /* left: 146px ; */
  }
  .industries-main .slick-arrow.slick-next {
    /* right: 146px !important; */
  }
}
@media screen and (max-width: 450px) {
  .industries-main .slick-arrow.slick-prev {
    /* left: 132px !important; */
    /* top: 380px !important; */
  }
  .industries-main .slick-arrow.slick-next {
    /* right: 132px !important; */
    /* top: 380px !important; */
  }
  .industries-main .column-gap .slick-slide img {
    height: 230px;
  }
  .industries-main .nav-tabs .nav-link {
    padding-left: 16px;
  }
}
@media screen and (max-width: 430px) {
  .industries-main .nav-tabs .nav-link {
    /* padding-left: 18px; */
    font-size: 13px;
  }
  .industries-main .column-gap .slick-slide img {
    height: 220px;
  }
  .industries-main .slick-arrow.slick-prev {
    /* left: 124px !important; */
    /* top: 360px !important; */
  }
  .industries-main .slick-arrow.slick-next {
    /* right: 124px !important; */
    /* top: 360px !important; */
  }
}
@media screen and (max-width: 410px) {
  .industries-main .column-gap .slick-slide img {
    height: 210px;
  }
  .industries-main .slick-arrow.slick-prev {
    /* left: 110px !important; */
    /* top: 360px !important; */
  }
  .industries-main .slick-arrow.slick-next {
    /* right: 110px !important; */
    /* top: 360px !important; */
  }
  .industries-main .nav-tabs .nav-link {
    padding-left: 14px;
    font-size: 12px;
  }
}
@media screen and (max-width: 375px) {
  .industries-main .column-gap .slick-slide img {
    height: 190px;
  }
  /* .industries .all-brands-resp {
    margin-top: 75px;
  } */
  .industries-main .slick-arrow.slick-prev {
    /* left: 100px !important; */
    /* top: 355px !important; */
  }
  .industries-main .slick-arrow.slick-next {
    /* right: 100px !important; */
    /* top: 355px !important; */
  }
  .industries-main .all-brands-resp {
    bottom: -100px;
  }
  .industries-main .nav-tabs .nav-link {
    padding-left: 12px;
    font-size: 11px;
  }
}
@media screen and (max-width: 360px) {
  .industries-main .column-gap .slick-slide img {
    height: 172px;
  }
  .industries-main .slick-arrow.slick-prev {
    /* left: 100px !important; */
    /* top: 330px !important; */
    width: 50px !important;
  }
  .industries-main .slick-arrow.slick-next {
    /* right: 100px !important; */
    /* top: 330px !important; */
    width: 50px !important;
  }
  .products h6 {
    font-size: 14px;
  }
}
@media screen and (max-width: 340px) {
  .industries-main .nav-tabs .nav-link {
    font-size: 10px;
    padding-left: 10px;
  }
  .industries-main .slick-arrow.slick-prev {
    /* left: 90px !important; */
  }
  .industries-main .slick-arrow.slick-next {
    /* right: 90px !important; */
  }
  .industries-main .column-gap .slick-slide img {
    height: 160px;
  }
  .industries-main .industries-tabs {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 320px) {
  .industries-main .slick-arrow.slick-prev {
    /* top: 320px !important; */
  }
  .industries-main .slick-arrow.slick-next {
    /* top: 320px !important; */
  }
}

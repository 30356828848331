* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  width: 100%;
}
.main-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.inner-layout {
  flex: 1;
}
.inner-layout.scrolled {
  margin-top: 0;
}
.Toastify__toast-body > div {
  font-family: "Regular";
}
@media screen and (max-width: 991px) {
  .inner-layout {
    margin-top: 100px;
    transition: margin-top 0.3s linear;
  }
}
@media screen and (max-width: 575px) {
  .inner-layout {
    margin-top: 60px;
  }
  /* .resp-toast {
    margin-bottom: 80px !important;
    padding: 20px !important;
  } */
  .Toastify__toast-container--bottom-right {
    margin-bottom: 80px;
  }
  .Toastify__toast-body > div:last-child {
    font-family: 'Medium';
  }
  .Toastify__toast-theme--light {
    margin: 18px !important;
  }
}
